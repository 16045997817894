import React, { useState, useEffect, useContext } from "react";
import SimpleNavigator from "../SimpleNavigator";
import { useHistory } from "react-router-dom";
import "../../../assets/styles/common-styles.css";
import { getData, postData } from "../../services/service-call";
import { useSelector } from "react-redux";
import {
  APP_SUBMISSION_CONFIG,
  applicationConstants,
  desc,
} from "../../config/application-submission-config";
import { alertService } from "../../_services/alert.service";
import { CREDIT_CARD_SVG } from "../ImageDataSource";
import { SignaturePad } from "../../components/SignatureCanvas";

import {
  ARTISANAL_FISHING,
  AS_A_CARRIER,
  ArtisanalTypeofCarrierMapping,
  Break,
  CARRIER_FISHING,
  COMMERCIAL,
  COMMERCIAL_FISHING,
  ID_CARD_RENEWAL,
  INDUSTRIAL_FISHING,
  IRISH_MOSS_SEA_WEEDS,
  LIC_PER_ID_CARD_RENEWAL,
  LIC_PER_RENEWAL,
  Morant_Cays,
  NEW_LICENCE,
  Pedro_cays,
  RECREATIONAL,
  RECREATIONAL_FISHING,
  REGULAR_LOBSTER,
  RENEWAL,
  emailPattern,
  formatPhoneNumber,
  getMinDateTimeForAtleastYear,
  recreation_type_of_licence,
  telephonePattern,
} from "../DataSource";
import { FLISContext } from "../../context/FLISContext";
import {
  CASH_FIELD_OFFICER,
  CASH_NFA_OFFICE,
  CHEQUE,
  CREDIT_CARD,
  DIRECT_DEBIT,
  PaymentTypeMetaData,
  WIRE_TRANSFER,
  fees,
  fisherIdOptions,
  fisherIdOptionsMapping,
} from "../../config/fees";
import {
  FormHookInput,
  FormHookRadioInput,
  FormHookSelect,
} from "../FormHook/ReactFormHookFields";
import { useForm } from "react-hook-form";
import { FormHookFileUploadHandler } from "../FormHook/FormHookDocument";
import { PaymentTypes as paymentType } from "../../config/fees";
import {
  INDUSTRIAL_VESSEL_CREW_MEMBER,
  MORANT_PEDRO,
  SPORTS_TOURNAMENT,
  TEMPORARY_PERMIT,
} from "../../containers/PermitLicence/PermitDataSource";
import { saveAndContinueLaterText, savedToDraft } from "../../utils/utils";
import { ROUTES } from "../../config";
import { ResponsiveTable } from "../ResponsiveTable";
import IframeComponent from "./IframeComponent";
import { emailNotValid, telephoneNotValid } from "../../utils/messages";
import AlertDialogSlide from "../Modal/Modal";

const PAYMENT_COMPLETED = 'PAYMENT_COMPLETED';
const PAYMENT_FAILED = "PAYMENT_FAILED";



function Payment(props) {
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState(1);
  const temp_trans_id = useSelector(
    (state) => state.currentTransaction.temp_trans_id
  );

  const [paymentStatus, setPaymentStatus] = useState("");

  let appType = useSelector((state) => {
    return state.currentTransaction.appType;
  });

  let appDetails = APP_SUBMISSION_CONFIG[appType] || {};

  let routingDetails = appDetails.steps.filter((step) => {
    if (step.currentStep === history.location.pathname) {
      return true;
    }
    return false;
  })[0];

  const nextAction = () => {
    history.push(routingDetails.next);
  };

  const previousAction = () => {
    history.push(routingDetails.previous);
  };


  const [iFrameHTML, setIframeHTML] = useState('');
  const [paymentFailureReason, setPaymentFailureReason] = useState([]);
  const { selectedMap, setSelectedMap, currentEntity } =
    useContext(FLISContext);
  const [paymentInfoList, setPaymentInfoList] = useState([]);
  const [payment_method, setPaymentMethod] = useState(
    selectedMap?.payment_details?.payment_method
  );
  const [paymentInfoTotal, setPaymentInfoTotal] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [paymentInfo, setPaymentInfo] = useState(
    selectedMap.payment_details ?? {}
  );
  const [totalPaymentInfoList, setTotalPaymentInfoList] = useState([]);
  const [cardRenewalRequired, setCardRenewalRequired] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    getValues,
    trigger,
  } = useForm({
    defaultValues: selectedMap?.payment_details ?? {},
    mode: "all",
  });

  const { errors } = formState;
  const signaturePadValue = watch("payment_signature_draw_path");
  const fisherId = watch("fisher_id");
  const shouldExpediteProcess = watch("should_expedite_process");

  const fisherIdCardExpiryDate = watch("fisher_card_id_expiry_date");
  const [paymentAlreadyDone, setPaymentAlreadyDone] = useState(false);

  useEffect(() => {
    let paymentList = [];
    if (appType === "APPLICATION_FOR_LICENCE") {
      if (selectedMap.licence_type === COMMERCIAL_FISHING) {
        let general =
          fees[appType]?.[selectedMap.licence_type]?.[
            selectedMap.commercial_category
          ]["General"]?.[selectedMap.domicile] ?? [];
        if (!selectedMap?.type_of_species?.length || (selectedMap?.type_of_species?.length === 1 && selectedMap?.type_of_species?.indexOf(IRISH_MOSS_SEA_WEEDS) >= 0)) {
          general = [];
        }

        if(selectedMap?.type_of_species?.indexOf?.(IRISH_MOSS_SEA_WEEDS) >= 0) {
          general = general.concat(fees[appType]?.[selectedMap.licence_type]?.[
            selectedMap.commercial_category
          ][IRISH_MOSS_SEA_WEEDS]?.[selectedMap.domicile] ?? [])
        }
        let special =
          fees[appType]?.[selectedMap.licence_type]?.[
            selectedMap.commercial_category
          ][selectedMap.special_type_of_species]?.[selectedMap.domicile] ?? [];


        
        paymentList = [...general, ...special].filter((_) => _.value);

        if(selectedMap.type_of_transaction === RENEWAL && selectedMap.renewal_type === ID_CARD_RENEWAL) {
          paymentList = [];
        }
      } else if (selectedMap.licence_type === RECREATIONAL_FISHING) {
        let general =
          fees[appType]?.[selectedMap.licence_type]["General"]?.[
            selectedMap.domicile
          ] ?? [];
        if (!selectedMap?.type_of_species?.length || (selectedMap?.type_of_species?.length === 1 && selectedMap?.type_of_species?.indexOf(IRISH_MOSS_SEA_WEEDS) >= 0)) {
          general = [];
        }
        if(selectedMap?.type_of_species?.indexOf?.(IRISH_MOSS_SEA_WEEDS) >= 0) {
          general = general.concat(fees[appType]?.[selectedMap.licence_type]?.
            [IRISH_MOSS_SEA_WEEDS]?.[selectedMap.domicile] ?? [])
        }

        let special =
          fees[appType]?.[selectedMap.licence_type][
            selectedMap.special_type_of_species
          ]?.[selectedMap.domicile] ?? [];

        paymentList = [...general, ...special].filter((_) => _.value);
        if(selectedMap.type_of_transaction === RENEWAL && selectedMap.renewal_type === ID_CARD_RENEWAL) {
          paymentList = [];
        }
      }
      let paymentValue = 0;
      paymentList?.map((_) => {
        paymentValue += _.value;
        return _;
      });
      setPaymentInfoTotal(paymentValue);
      setPaymentInfoList(paymentList);
    } else if (appType === "APPLICATION_FOR_FISHING_VESSEL") {
      let list = [];
      if (selectedMap.licence_type === COMMERCIAL) {
        if (selectedMap.commercial_licence_type === CARRIER_FISHING) {
          if (
            selectedMap.vessel_commercial_carrier_type === "Industrial Carrier"
          ) {
            let targetFishList =
              fees[appType]?.[selectedMap.licence_type]?.[
                selectedMap.commercial_licence_type
              ]?.[selectedMap.vessel_commercial_carrier_type]?.[
                selectedMap.target_fish
              ] || [];
            let specialTargetFishList =
              fees[appType]?.[selectedMap.licence_type]?.[
                selectedMap.commercial_licence_type
              ]?.[selectedMap.vessel_commercial_carrier_type]?.[
                selectedMap.special_target_fish
              ] || [];

            list = [...targetFishList, ...specialTargetFishList];
          } else if (
            selectedMap.vessel_commercial_carrier_type === "Artisanal Carrier"
          ) {
            let targetList =
              fees[appType]?.[selectedMap.licence_type]?.[
                selectedMap.commercial_licence_type
              ]?.[selectedMap.vessel_commercial_carrier_type]?.[
                selectedMap.vessel_type
              ] || [];

            list = targetList;
          }

          let vesselInsp =
            fees[appType]?.[selectedMap.licence_type]?.[
              selectedMap.commercial_licence_type
            ]?.["Vessel Inspection"] || [];

          list = list.concat(vesselInsp);
        } else if (selectedMap.commercial_licence_type === INDUSTRIAL_FISHING) {
          let source = selectedMap.fishery_type;

          source?.forEach((element) => {
            let targetList =
              fees[appType]?.[selectedMap.licence_type]?.[
                selectedMap.commercial_licence_type
              ]?.[element] || [];
            list = list.concat(targetList);
          });

          let vesselInsp =
            fees[appType]?.[selectedMap.licence_type]?.[
              selectedMap.commercial_licence_type
            ]?.["Vessel Inspection"] || [];

          list = list.concat(vesselInsp);
        } else if (selectedMap.commercial_licence_type === ARTISANAL_FISHING) {
          let targetList =
            fees[appType]?.[selectedMap.licence_type]?.[
              selectedMap.commercial_licence_type
            ]?.[selectedMap.commercial_artisanal_vessel_type]?.[
              selectedMap.commercial_artisanal_target_fish_type
            ] || [];

         // a check for fishing region if morant and pedro cays is selected
          if ((selectedMap.region === Pedro_cays || selectedMap.region === Morant_Cays) && (selectedMap.commercial_artisanal_target_fish_type === REGULAR_LOBSTER)) {

            targetList = [{
              name: REGULAR_LOBSTER,
              value: 6300,
            }];
          }

          // vessel inspection fee
          let vesselInpectionFee =
            fees[appType]?.[selectedMap.licence_type]?.[
              selectedMap.commercial_licence_type
            ]?.[`${selectedMap.commercial_artisanal_vessel_type}_INSPECTION`] ||
            [];

            // artisanal carrier fee
          let vesselCarrierFee =
            fees[appType]?.[selectedMap.licence_type]?.[
            selectedMap.commercial_licence_type
            ]?.[`${ArtisanalTypeofCarrierMapping[selectedMap.commercial_artisanal_carrier_type]}_CARRIER`] ||
            [];

          // no target fish fees for Carrier Artisanal Motor
          if(selectedMap.commercial_artisanal_carrier_type === AS_A_CARRIER) {
            targetList = [];
          }

          list = [...targetList, ...vesselInpectionFee, ...vesselCarrierFee];
        }
      } else if (selectedMap.licence_type === RECREATIONAL) {
        const recreationalType = selectedMap.recreational_licence_type;

        // recreationalTypes.forEach((recType) => {
        //   const key = recreation_type_of_licence[recType].key;
        //   let targetList =
        //     fees[appType]?.[selectedMap.licence_type]?.[recType]?.[
        //       selectedMap?.[key]
        //     ] || [];
        //   list = list.concat(targetList);
        // });

         
          const key = recreation_type_of_licence[recreationalType].key;
          let targetList =
            fees[appType]?.[selectedMap.licence_type]?.[recreationalType]?.[
              selectedMap?.[key]
            ] || [];
          list = list.concat(targetList);
        
      }

      let paymentValue = 0;
      list?.map((_) => {
        paymentValue += _.value;
        return _;
      });
      setPaymentInfoTotal(paymentValue);
      setPaymentInfoList(list);
    } else if (appType === "APPLICATION_FOR_PERMIT") {
      let list = [];
      if (selectedMap.type_of_permit === TEMPORARY_PERMIT) {
        if (selectedMap.purpose_of_permit === SPORTS_TOURNAMENT) {
          list =
            fees[appType]?.[selectedMap.type_of_permit]?.[
              selectedMap.purpose_of_permit
            ]?.[selectedMap.nationality] ?? [];
        } else {
          list =
            fees[appType]?.[selectedMap.type_of_permit]?.[
              selectedMap.purpose_of_permit
            ] ?? [];
        }
      } else if (selectedMap.type_of_permit === INDUSTRIAL_VESSEL_CREW_MEMBER) {
        list =
          fees[appType][selectedMap.type_of_permit](
            selectedMap?.vessel_names?.length ?? 1
          ) ?? [];
      } else {
        list = fees[appType][selectedMap.type_of_permit] ?? [];
      }

      // exception for id card renewal
      if(selectedMap.type_of_transaction === RENEWAL && selectedMap.renewal_type === ID_CARD_RENEWAL) {
        list = [];
      }

      setPaymentInfoList(list);
      let paymentValue = 0;
      list?.map((_) => {
        paymentValue += _.value;
        return _;
      });
      setPaymentInfoTotal(paymentValue);
    } else if (appType === "APPLICATION_FOR_AUTHORIZATION") {
      let list = fees[appType]?.[selectedMap.type_of_authorization];
      setPaymentInfoList(list);
      let paymentValue = 0;
      list?.map((_) => {
        paymentValue += _.value;
        return _;
      });
      setPaymentInfoTotal(paymentValue);
    } else if (appType === "APPLICATION_FOR_GENERAL_ADMINISTRATION") {
      // let list = fees[appType];
      let list = fees?.[appType]?.[selectedMap.type_of_letter_requested];
      setPaymentInfoList(list);
      let paymentValue = 0;
      list?.map((_) => {
        paymentValue += _.value;
        return _;
      });
      setPaymentInfoTotal(paymentValue);
    } else if (appType === "APPLICATION_FOR_AQUACULTURE_FISHER") {
      let list = fees[appType];
     
      // exception for id card renewal
      if(selectedMap.type_of_transaction === RENEWAL && selectedMap.renewal_type === ID_CARD_RENEWAL) {
        list = [];
      }


      setPaymentInfoList(list);

      let paymentValue = 0;
      list?.map((_) => {
        paymentValue += _.value;
        return _;
      });

      setPaymentInfoTotal(paymentValue);
    }
  }, []);

  useEffect(() => {
    if (!fisherIdCardExpiryDate) return;

    const todaysDate = new Date();
    const expiryDate = new Date(fisherIdCardExpiryDate);
    const isNewLicence = selectedMap.renewal_type === LIC_PER_RENEWAL;
    if (todaysDate > expiryDate) {
      setCardRenewalRequired(true);

        setSelectedMap((data) => {
          return {
            ...data,
            // renewal_type: LIC_PER_ID_CARD_RENEWAL,
            card_renewal_required: true

          }
        })

    } else {
      // setCardRenewalRequired(false);
      const isRenewalCardRequired = isNewLicence ? false : true;
      setSelectedMap((data) => {
        return {
          ...data,
          // renewal_type: LIC_PER_ID_CARD_RENEWAL,
          card_renewal_required: isRenewalCardRequired
        }
      })
      setCardRenewalRequired(isRenewalCardRequired);
      setValue("fisher_id", null);
    }
  }, [fisherIdCardExpiryDate]);



  useEffect(() => {
    // to calculate the fisher id fee value start
    const fisherIdFeeValue = fisherIdOptionsMapping[fisherId]?.value ?? 0;
    const fisherIdFee = parseInt(fisherIdFeeValue)
      ? parseInt(fisherIdFeeValue)
      : 0;
    setTotalPayment((paymentInfoTotal ?? 0) + (fisherIdFee ?? 0));
    const fisherIdInfo = fisherIdFee
      ? [
          {
            name: `ID - ${fisherIdOptionsMapping[fisherId]?.name}`,
            value: fisherIdFee,
          },
        ]
      : [];
    // to calculate the fisher id fee value end

    const expediteLicence =
      shouldExpediteProcess === "Yes"
        ? [
            {
              name: "Expedite Licence",
              value: 500,
            },
          ]
        : [];

    setTotalPaymentInfoList([
      ...paymentInfoList,
      ...fisherIdInfo,
      ...expediteLicence,
    ]);
  }, [fisherId, paymentInfoTotal, shouldExpediteProcess]);

  useEffect(() => {
    let totalPaymentLocal = 0;
    totalPaymentInfoList?.map((_) => {
      totalPaymentLocal += _.value;
    });

    setTotalPayment(totalPaymentLocal);
  }, [totalPaymentInfoList]);

  // Add event listener when component mounts
  useEffect(() => {
    window.addEventListener('message', handleMessage);
    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);


  const cardIDTextMapping = {
    'APPLICATION_FOR_FISHER_WORKER_ID': 'Fish Worker ID'
  }

  const FisherID = (
    <tr>
      <td>
        <div className="d-flex flex-row justify-content-start">
          <div style={{
              marginRight: "20px",
              marginTop: "5px",
            }}>
            <b>{cardIDTextMapping[appType] || 'FISHER ID'}</b>
          </div>

          <FormHookRadioInput
            parentClass="individual-field"
            fieldClassName="d-flex-wrap flex-row"
            hideLabel={true}
            register={register}
            setValue={setValue}
            regFieldName="fisher_id"
            options={fisherIdOptions}
            error={errors?.fisher_id?.message}
            isDisabled={false}
            isMulti={false}
            isKeyValue={true}
          />

          <FormHookInput
            register={register}
            error={errors?.address_fisher_id?.message}
            regFieldName="address_fisher_id"
            label="Address for Card Collection"
            isDisabled={fisherId !== "In-Field"}
            isRequired={
              fisherId === "In-Field" ? "This field is required" : false
            }
            toAddOptionalText={false}
            labelClassName="zero-margin-top"
          />
        </div>
      </td>

      <td className="right-align">
        <label>
          {fisherId ? `JMD ${fisherIdOptionsMapping[fisherId].value}` : "NA"}
        </label>
      </td>
    </tr>
  );

  const FisherIDReIssuance = (
    <tr>
      <td>
        <div className="d-flex flex-row justify-content-start">
          <div
            style={{
              marginRight: "20px",
              marginTop: "5px",
            }}
          >
            {
              appType === "APPLICATION_FOR_PERMIT" ? <b>Permit Card Renewal: </b> : <b>ID Card Renewal: </b>
            }
            <Break/>
           {
            fisherIdCardExpiryDate &&  <div>Card Status: {new Date(fisherIdCardExpiryDate) > new Date() ? 'Current' : <b style={{color: 'red'}}>Expired</b>} </div>
           }
          </div>

          <FormHookInput
            register={register}
            error={errors?.fisher_card_id_issue_date?.message}
            regFieldName="fisher_card_id_issue_date"
            type="date"
            hideLabel={false}
            label={`Previous ${appType === "APPLICATION_FOR_PERMIT" ? "Permit": "ID"} Card Issue Date`}
            labelClassName="zero-margin-top"
          />

          <FormHookInput
            register={register}
            error={errors?.fisher_card_id_expiry_date?.message}
            regFieldName="fisher_card_id_expiry_date"
            type="date"
            hideLabel={false}
            label={`Previous ${appType === "APPLICATION_FOR_PERMIT" ? "Permit": "ID"} Card Expiry Date`}
            labelClassName="zero-margin-top"
          />

          {cardRenewalRequired && (
            <>
              <FormHookRadioInput
                parentClass="individual-field"
                fieldClassName="d-flex-wrap flex-column"
                hideLabel={true}
                register={register}
                setValue={setValue}
                regFieldName="fisher_id"
                options={fisherIdOptions}
                error={errors?.fisher_id?.message}
                isDisabled={false}
                isMulti={false}
                isKeyValue={true}
              />

              <FormHookInput
                register={register}
                error={errors?.address_fisher_id?.message}
                regFieldName="address_fisher_id"
                label="Address for Card Collection"
                isDisabled={fisherId !== "In-Field"}
                isRequired={
                  fisherId === "In-Field" ? "This field is required" : false
                }
                toAddOptionalText={false}
                labelClassName="zero-margin-top"
              />
            </>
          )}
        </div>
      </td>

      <td className="right-align">
        <label>
          {fisherId ? `JMD ${fisherIdOptionsMapping[fisherId].value}` : "NA"}
        </label>
      </td>
    </tr>
  );

  const ExpediteApplication = (
    <tr>
      <td>
        <div className="d-flex flex-row justify-content-start">
          <div
            style={{
              marginRight: "20px",
              marginTop: "5px",
            }}
          >
            <b>Expedited Licence/Permit: </b>
          </div>

          <FormHookRadioInput
            parentClass="max-200"
            fieldClassName="d-flex-wrap flex-row"
            hideLabel={true}
            register={register}
            setValue={setValue}
            regFieldName="should_expedite_process"
            options={["Yes", "No"]}
            error={errors?.should_expedite_process?.message}
            isMulti={false}
          />

          <FormHookInput
            register={register}
            error={errors?.expedited_application_date_time?.message}
            regFieldName="expedited_application_date_time"
            type="datetime-local"
            hideLabel={false}
            label="Date and Time - Licence/Permit Certificate Required"
            isDisabled={shouldExpediteProcess !== "Yes"}
            isRequired={
              shouldExpediteProcess === "Yes" ? "This field is required" : false
            }
            toAddOptionalText={false}
            labelClassName="zero-margin-top"
            minDate={getMinDateTimeForAtleastYear(0)}
          />
        </div>
      </td>

      <td className="right-align">
        <label>{shouldExpediteProcess === "Yes" ? `JMD ${500}` : "NA"}</label>
      </td>
    </tr>
  );

  const HeaderFees = (
    <thead>
      <tr>
        <th>
          {applicationConstants[appType].feesTypeHeader ?? "Fishing Category"}
        </th>
        <th className="right-align">Amount</th>
      </tr>
    </thead>
  );

  const ApplicationInfo = (
    <div className="d-flex flex-row justify-content-between">
      <div>
        <b>Type of Licence</b> - {desc[appType]}
      </div>

      <div>
        <b>Type of Transaction</b> - {selectedMap.type_of_transaction}{" "}
      </div>

      <div>
        <b>NFA Registration #</b> - {currentEntity?.nfa_registration_number}
      </div>
    </div>
  );


 
  const PaymentInfoList = () => {
    return (
      <>
        {paymentInfoList?.map((payment) => {
          return (
            <tr>
              <td>{payment.name}</td>

              <td className="right-align">
                <label>{payment.value ? `JMD ${payment.value}` : "NA"}</label>
              </td>
            </tr>
          );
        })}
      </>
    );
  };

  const BankingInformation = (
    <div
      className="mt-2"
      style={{
        padding: "10px 10px ",
        border: "0.5px solid #444",
        maxWidth: "700px",
      }}
    >
      <ResponsiveTable headers={[]}>
        <h4 style={{ textAlign: "center" }}>Banking Information</h4>
        <tbody>
          <tr>
            <td><b>Account Name:</b> </td>
            <td>Ministry of Agriculture and Fisheries – Fisheries Division</td>
          </tr>

          <tr>
            <td><b>Bank:</b> </td>
            <td>Bank of Nova Scotia</td>
          </tr>

          <tr>
            <td><b>Branch:</b> </td>
            <td>Hagley Park Road</td>
          </tr>

          <tr>
            <td><b>Account Type:</b> </td>
            <td>Current</td>
          </tr>

          <tr>
            <td><b>Account Number:</b> </td>
            <td>6213-21</td>
          </tr>
        </tbody>
      </ResponsiveTable>
    </div>
  );


  const CreditCardInputInfo = (
    <div className="d-flex-wrap flex-row justify-content-start">
      <FormHookInput
        register={register}
        error={errors?.billing_address?.first_name?.message}
        regFieldName="billing_address.first_name"
        label="First Name"
      />

      <FormHookInput
        register={register}
        error={errors?.billing_address?.last_name?.message}
        regFieldName="billing_address.last_name"
        label="Last Name"
      />

      <FormHookInput
        register={register}
        error={errors?.billing_address?.address_line_1?.message}
        regFieldName="billing_address.address_line_1"
        label="Address Line 1"
      />

      <FormHookInput
        register={register}
        error={errors?.billing_address?.address_line_2?.message}
        regFieldName="billing_address.address_line_2"
        label="Address Line 2"
      />

      <FormHookInput
        register={register}
        error={errors?.billing_address?.city?.message}
        regFieldName="billing_address.city"
        label="City"
      />

      <FormHookInput
        register={register}
        error={errors?.billing_address?.state?.message}
        regFieldName="billing_address.state"
        label="State"
        isRequired={false}
      />

      <FormHookInput
        register={register}
        error={errors?.billing_address?.postal_code?.message}
        regFieldName="billing_address.postal_code"
        label="Postal Code"
      />

      <FormHookInput
        register={register}
        error={errors?.billing_address?.email_address?.message}
        regFieldName="billing_address.email_address"
        label="Email Address"
        validations={{
          pattern: {
            value: emailPattern,
            message: emailNotValid,
          },
        }}
      />

      <FormHookInput
        register={register}
        error={errors?.billing_address?.telephone_number?.message}
        regFieldName="billing_address.telephone_number"
        label="Phone Number"
        validations={{
          pattern: {
            value: telephonePattern,
            message: telephoneNotValid,
          },
        }}
        onChange={formatPhoneNumber}
      />

    </div>
  );

  const CreditCardPaymentSuccess = (
    <div className="d-flex-wrap flex-row justify-content-start">
      <div style={{color: 'green'}}><b>Your Payment is Completed Successfully</b></div>
    </div>
  );


  const CreditCardPaymentFailure = (
    <div className="d-flex-wrap flex-row justify-content-start">
      <div style={{color: 'red'}}><b>Your Payment Failed Due to following reasons</b></div>
      <div>
        <b>
          {JSON.stringify(paymentFailureReason, null, 2)}
        </b>
      </div>
    </div>
  );


   // Event listener to handle messages sent from iframe
   const handleMessage = async (event) => {
    // Check if the message is from a trusted source (optional)
    // For example, you can check event.origin
    // if (event.origin !== 'https://example.com') return;

    // Log the received message
    console.log('Message from iframe:', event.data);

    if(event?.data?.done && event?.data?.temp_trans_id === temp_trans_id) {
      setIframeHTML(PAYMENT_COMPLETED);
      nextAction();
    } else if(event?.data?.temp_trans_id === temp_trans_id && !event?.data?.done) {
      setIframeHTML(PAYMENT_FAILED);
      getPaymentFailureReason();
    }
  };

  const getPaymentFailureReason = async () => {
    const result = await getData({
      url: `${ROUTES.getPaymentFailureReason}/${temp_trans_id}`
    });
    console.log(result);
    setIframeHTML(PAYMENT_FAILED);
    setPaymentFailureReason(JSON.parse(result?.error_message) || []);
  }

  const saveAndContinueLater = async ({toCloseApp}) => {


    let finalValue = {};
          if (currentStep === 1 || currentStep === 2) {
            const values = getValues();
            finalValue = {
              ...selectedMap,
              payment_details: {
                ...paymentInfo,
                ...values,
                payment_method,
              },
            };
          } else if (currentStep === 3) {
            const values = getValues();
            finalValue = {
              ...selectedMap,
              payment_details: {
                ...paymentInfo,
                ...values,
                payment_list: totalPaymentInfoList,
                amount_due: totalPayment,
                payment_method,
              },
            };
          }

          const result = await postData({
            url: `${ROUTES.saveApplication}?app_type=${appType}`,
            body: {
              ...finalValue,
              temp_trans_id: temp_trans_id,
              app_type: appType,
              launchCase: false,
            },
          });

          if (result.statusCode === 200) {
            if(toCloseApp) {
              alertService.success(savedToDraft, {
                autoClose: true,
                keepAfterRouteChange: true,
              });
  
              history.replace("/application-types");
            }

            return true;
          } else {
            alertService.error("Error occured while saving application", {
              autoClose: true,
              keepAfterRouteChange: true,
            });

            return false;
          }

  }

  
  

  return (
    <div>
      <AlertDialogSlide
        fullWidth={false}
        open={paymentAlreadyDone}
        title={"Previous Payment Found"}
        handleClose={() => {
          nextAction();
        }}
        handleCloseSecondary={() => {
          setPaymentAlreadyDone(false);
        }}
        secondaryActionName="Submit Application"
        primaryActionName="Continue with payment"
        style={{ margin: "auto" }}
        content={
          <div style={{margin: '20px'}}>
            A Payment was already recored earlier for an amount of JMD{" "}
            {selectedMap?.payment_details?.credit_card_amount_recorded}. Would you
            like to directly submit the application.
          </div>
        }
      />

      <div className="shadow-sm  m-3 bg-light rounded ">
        <div className="head-text-fees">Payment of Fees</div>

        <div className="pt-2 steps d-flex justify-content-center flex-spacing-10 ">
          {/* simple-border-left simple-border-right */}
          <div
            className={currentStep === 1 ? "button-step-active" : "button-step"}
          >
            Payment Form
          </div>

          <div style={{ marginTop: "5px" }} className="triangle-right"></div>

          <div
            className={currentStep === 2 ? "button-step-active" : "button-step"}
          >
            Select Payment Method
          </div>

          <div style={{ marginTop: "5px" }} className="triangle-right"></div>

          <div
            className={currentStep === 3 ? "button-step-active" : "button-step"}
          >
            {currentStep === 3 ? payment_method : "Next Step"}
          </div>
        </div>

        {currentStep === 1 && (
          <div className=" mb-4 pt-4 pl-4 pr-4 ">
            {/* simple-border-left simple-border-right simple-border-bottom */}

            <div className="">
              {appType === "APPLICATION_FOR_LICENCE" && (
                <div className="little-big-text">
                  {ApplicationInfo}

                  <div className="table-responsive left-align">
                    <table className="table table-bordered table-sm">
                      {HeaderFees}
                      <tbody>
                        {/* Fisher ID Provision */}
                        {selectedMap.type_of_transaction === NEW_LICENCE &&
                          FisherID}

                        {selectedMap.type_of_transaction === RENEWAL &&
                          FisherIDReIssuance}

                        {ExpediteApplication}
                        <PaymentInfoList></PaymentInfoList>
                      </tbody>
                    </table>
                  </div>

                  <div className="d-flex flex-row justify-content-end ">
                    <div>
                      <b>Total Fees Due: ${totalPayment}</b>
                    </div>
                  </div>
                </div>
              )}

              {appType === "APPLICATION_FOR_GENERAL_ADMINISTRATION" && (
                <div className="little-big-text">
                  {ApplicationInfo}
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      {HeaderFees}
                      <tbody>
                        <tr>
                          <td
                            className="background-blue text-bold little-big-text"
                            colSpan={2}
                          ></td>
                        </tr>

                        {ExpediteApplication}
                        <PaymentInfoList />
                      </tbody>
                    </table>

                    <div className="d-flex flex-row justify-content-end">
                      <div>
                        <b>Total Fees Due: ${totalPayment}</b>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {appType === "APPLICATION_FOR_AUTHORIZATION" && (
                <div className="little-big-text">
                  {ApplicationInfo}
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      {HeaderFees}
                      <tbody>
                        <tr>
                          <td
                            className="background-blue text-bold little-big-text"
                            colSpan={2}
                          ></td>
                        </tr>

                        {ExpediteApplication}
                        <PaymentInfoList />
                      </tbody>
                    </table>

                    <div className="d-flex flex-row justify-content-end ">
                      <div>
                        <b>Total Fees Due: ${totalPayment}</b>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {appType === "APPLICATION_FOR_FISHING_VESSEL" && (
                <div className="little-big-text">
                  {ApplicationInfo}

                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      {HeaderFees}
                      <tbody>
                        <tr>
                          <td
                            className="background-blue text-bold little-big-text"
                            colSpan={2}
                          ></td>
                        </tr>

                        {ExpediteApplication}
                        <PaymentInfoList />
                      </tbody>
                    </table>
                  </div>

                  <div className="d-flex flex-row justify-content-end ">
                    <div>
                      <b>Total Fees Due: ${totalPayment}</b>
                    </div>
                  </div>
                </div>
              )}

              {appType === "APPLICATION_FOR_PERMIT" && (
                <div className="little-big-text">
                  {ApplicationInfo}

                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      {HeaderFees}
                      <tbody>
                        <tr>
                          <td
                            className="background-blue text-bold little-big-text"
                            colSpan={2}
                          ></td>
                        </tr>

                        {/* Fisher ID Provision */}

                        {/* Zahra asked to remove */}

                        {/* {selectedMap.type_of_transaction === "New Permit" &&
                          [INDUSTRIAL_VESSEL_CREW_MEMBER, MORANT_PEDRO].indexOf(
                            selectedMap.type_of_permit
                          ) >= 0 &&
                          FisherID} */}

                        {/* Zahra asked to remove */}
                        {/* {selectedMap.type_of_transaction === RENEWAL &&
                          FisherIDReIssuance} */}

                        {ExpediteApplication}
                        <PaymentInfoList />
                      </tbody>
                    </table>

                    <div className="d-flex flex-row justify-content-end ">
                      <div>
                        <b>Total Fees Due: ${totalPayment}</b>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-row justify-content-center "></div>
                </div>
              )}

              {appType === "APPLICATION_FOR_AQUACULTURE_FISHER" && (
                <div className="little-big-text">
                  {ApplicationInfo}

                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      {HeaderFees}
                      <tbody>
                        {selectedMap.type_of_transaction === NEW_LICENCE &&
                          FisherID}

                        {selectedMap.type_of_transaction === RENEWAL &&
                          FisherIDReIssuance}

                        {ExpediteApplication}
                        <PaymentInfoList />
                      </tbody>
                    </table>
                  </div>

                  <div className="d-flex flex-row justify-content-end ">
                    <div>
                      <b>Total Fees Due: ${totalPayment}</b>
                    </div>
                  </div>
                </div>
              )}

              {appType === "APPLICATION_FOR_FISHER_WORKER_ID" && (
                <div className="little-big-text">
                  {ApplicationInfo}

                  <div className="table-responsive left-align">
                    <table className="table table-bordered table-sm">
                      {HeaderFees}
                      <tbody>
                        {/* Fisher ID Provision */}
                        {selectedMap.type_of_transaction === NEW_LICENCE &&
                          FisherID}

                        {selectedMap.type_of_transaction === RENEWAL &&
                          FisherIDReIssuance}

                        {ExpediteApplication}
                      </tbody>
                    </table>
                  </div>

                  <div className="d-flex flex-row justify-content-end ">
                    <div>
                      <b>Total Fees Due: ${totalPayment}</b>
                    </div>
                  </div>
                </div>
              )}

              <SimpleNavigator
                className="mb-2 mt-2"
                shouldAddPadMar={false}
                next
                nextValue="Continue with Payment"
                nextAction={async () => {
                  if (await trigger()) {
                    const values = getValues();
                    setPaymentInfo((map) => {
                      return {
                        ...map,
                        ...values,
                      };
                    });
                    setCurrentStep(2);

                    // if (
                    //   selectedMap.payment_details
                    //     .credit_card_order_identifier &&
                    //   selectedMap.payment_details.credit_card_amount_recorded >=
                    //     totalPayment
                    // ) {
                    //   setPaymentAlreadyDone(true);
                    // } else {
                    //   setCurrentStep(2);
                    // }
                  }
                }}
              />
            </div>
          </div>
        )}

        {currentStep === 2 && (
          <div className="mt-4 d-flex flex-column justify-content-center flex-spacing-10">
            <div className="d-flex-wrap bottom-spacing-10 justify-content-center flex-spacing-10">
              {paymentType.map((trans, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      if (trans.isDisabled) return;
                      setCurrentStep(3);
                      setPaymentMethod(trans.type);
                    }}
                    className={`p-3 ${
                      payment_method === trans.type
                        ? "payment-type-cat-container-selected"
                        : "payment-type-cat-container"
                    }`}
                    style={{
                      opacity: trans.isDisabled ? 0.5 : 1,
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      {trans.image(
                        payment_method === trans.type ? "#fff" : "#047247"
                      )}

                      <div style={{ fontSize: "24px", fontWeight: "600" }}>
                        {trans.type}
                      </div>

                      <div>{trans.description}</div>
                    </div>
                  </div>
                );
              })}

              {/* <div  style={{ color: "red", fontWeight: "600", fontSize: "20px" }}>Payment Method - Credit/Debit Card is currently not available, please select another payment method</div> */}
            </div>

            <div className="mt-2">
              <SimpleNavigator
                previous="Back"
                prevValue="Back"
                previousAction={() => {
                  setCurrentStep(1);
                }}
                nextAction={() => {
                  setCurrentStep(3);
                }}
              ></SimpleNavigator>
            </div>
          </div>
        )}

        {currentStep === 3 && payment_method === CREDIT_CARD && (
          <div className="d-flex-wrap justify-content-center">
            <div
              className="mt-2"
              style={{
                padding: "10px 10px ",
                border: "0.5px solid #444",
                maxWidth: "1000px",
              }}
            >
              <div className="d-flex flex-spacing-10">
                {CREDIT_CARD_SVG(60, 60)}

                <div className="pt-1">
                  <div>
                    <b>Credit Card</b>
                  </div>
                  <div>Pay directly through Credit Card</div>
                </div>
              </div>

              <div className="d-flex-wrap flex-row justify-content-between">
                <div>
                  <b>{applicationConstants?.[appType]?.feeHeader}</b> -{" "}
                  <span style={{ fontSize: "20px" }}>JMD$ {totalPayment}</span>
                </div>
              </div>

              <div className="d-flex-wrap flex-spacing-10">
                {!iFrameHTML && CreditCardInputInfo}

                {iFrameHTML &&
                  iFrameHTML !== PAYMENT_COMPLETED &&
                  iFrameHTML !== PAYMENT_FAILED && (
                    <IframeComponent iFrameHTML={iFrameHTML}></IframeComponent>
                  )}

                {iFrameHTML &&
                  iFrameHTML === PAYMENT_COMPLETED &&
                  CreditCardPaymentSuccess}

                {iFrameHTML &&
                  iFrameHTML === PAYMENT_FAILED &&
                  CreditCardPaymentFailure}
              </div>

              <div className="d-flex-wrap justify-content-between">
                <button
                  onClick={() => {
                    setCurrentStep(2);
                  }}
                  className="mt-2 btn btn-custom-warning"
                >
                  Back
                </button>

                <button
                  onClick={() => {
                    setCurrentStep(2);
                  }}
                  className="mt-2 ml-2  btn btn-custom-warning"
                >
                  Change Payment Method
                </button>

                {!iFrameHTML && (
                  <button
                    onClick={async () => {

                      if(!await trigger()) {
                        return;
                      }

                      if(!await saveAndContinueLater({toCloseApp: false})) {
                        return;
                      }

                      const values = getValues();

                      const payment_details_internal = {
                        ...paymentInfo,
                        ...values,
                        payment_method,
                        payment_list: totalPaymentInfoList,
                        amount_due: totalPayment,
                      };

                      setSelectedMap((map) => {
                        return {
                          ...map,
                          payment_details: payment_details_internal,
                        };
                      });
                      const result = await postData({
                        url: `${ROUTES.initiatePayment}/${temp_trans_id}`,
                        body: payment_details_internal,
                      });

                      if (result.statusCode === 200) {
                        console.log(result);
                        const scriptToInject = result.data.RedirectData;
                        setIframeHTML(scriptToInject);
                      } else {
                        getPaymentFailureReason();
                        alertService.error(
                          "Error occured while initiating payment",
                          {
                            autoClose: true,
                            keepAfterRouteChange: true,
                          }
                        );
                      }


                    }}
                    style={{ fontWeight: "600" }}
                    className="mt-2 btn btn-danger"
                  >
                    Make Payment
                  </button>
                )}

                {iFrameHTML && iFrameHTML === PAYMENT_COMPLETED && (
                  <div>
                    <button
                      className=" mt-2 ml-2  btn btn-primary"
                      onClick={() => {
                        nextAction();
                      }}
                    >
                      Proceed
                    </button>
                  </div>
                )}

                {iFrameHTML && iFrameHTML === PAYMENT_FAILED && (
                  <div>
                    <button
                      className="mt-2  ml-2 btn btn-primary"
                      onClick={() => {
                        setIframeHTML("");
                      }}
                    >
                      Try Again
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {currentStep === 3 &&
          [WIRE_TRANSFER, DIRECT_DEBIT, CHEQUE].indexOf(payment_method) !==
            -1 && (
            <div>
              <div className="d-flex-wrap justify-content-center">
                <div
                  className="mt-2"
                  style={{
                    padding: "10px 10px ",
                    border: "0.5px solid #444",
                    maxWidth: "700px",
                    marginRight: "10px",
                  }}
                >
                  <div className="d-flex-wrap  flex-spacing-10">
                    {CREDIT_CARD_SVG(60, 60)}

                    <div className="pt-1">
                      <div>
                        <b>{payment_method}</b>
                      </div>
                      <div>{paymentType[1].description}</div>
                    </div>
                  </div>

                  <div className="d-flex-wrap flex-row justify-content-between">
                    <div>
                      <b>{applicationConstants?.[appType]?.feeHeader}</b> -{" "}
                      <span style={{ fontSize: "20px" }}>
                        JMD$ {totalPayment}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex-wrap  flex-spacing-10">
                    <FormHookInput
                      register={register}
                      error={errors?.amount_paid?.message}
                      label="Amount Paid"
                      regFieldName="amount_paid"
                      placeholder="Amount Paid"
                      type="number"
                    />

                    <FormHookSelect
                      register={register}
                      error={errors?.amount_paid_currency?.message}
                      label="Currency"
                      regFieldName="amount_paid_currency"
                      options={["JMD", "USD", "Pound", "Euro"]}
                    />

                    <FormHookFileUploadHandler
                      error={errors?.proof_of_payment?.message}
                      register={register}
                      watch={watch}
                      label="Upload Proof of Payment"
                      accept="*"
                      regFieldName="proof_of_payment"
                      fieldClassName="field-block-control max-250"
                      isPreviewable={false}
                      isRequired={"This is required"}
                      removeFile={(id) => setValue(id, null)}
                      setValue={setValue}
                    />
                  </div>

                  <div className="d-flex-wrap justify-content-between">
                    <button
                      onClick={() => {
                        setCurrentStep(2);
                      }}
                      className="mt-2 btn btn-custom-warning"
                    >
                      Back
                    </button>

                    <button
                      onClick={async () => {
                        if (await trigger()) {
                          const values = getValues();
                          setSelectedMap((map) => {
                            return {
                              ...map,
                              payment_details: {
                                ...paymentInfo,
                                ...values,
                                payment_method,
                                payment_list: totalPaymentInfoList,
                                amount_due: totalPayment,
                              },
                            };
                          });

                          nextAction();
                        }
                      }}
                      style={{ fontWeight: "600" }}
                      className="mt-2 btn btn-danger"
                    >
                      Proceed Further
                    </button>
                  </div>
                </div>

                {BankingInformation}
              </div>
            </div>
          )}

        {currentStep === 3 &&
          [CASH_FIELD_OFFICER].indexOf(payment_method) !== -1 && (
            <div className="d-flex-wrap justify-content-center">
              <div
                className="mt-2"
                style={{
                  padding: "10px 10px ",
                  border: "0.5px solid #444",
                  maxWidth: "1000px",
                }}
              >
                <div className="d-flex-wrap flex-spacing-10">
                  {CREDIT_CARD_SVG(60, 60)}

                  <div className="pt-1">
                    <div>
                      <b>{payment_method}</b>
                    </div>
                    <div>{PaymentTypeMetaData[CASH_FIELD_OFFICER]?.desc}</div>
                  </div>
                </div>

                <div className="d-flex-wrap flex-row justify-content-between">
                  <div>
                    <b>{applicationConstants?.[appType]?.feeHeader}</b> -{" "}
                    <span style={{ fontSize: "20px" }}>
                      JMD$ {totalPayment}
                    </span>
                  </div>
                </div>

                <div className="d-flex-wrap flex-spacing-10">
                  <FormHookInput
                    register={register}
                    error={errors?.name_of_field_officer?.message}
                    label="Name of Field Officer"
                    regFieldName="name_of_field_officer"
                    placeholder="Name of Field Officer"
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.date_payment_received?.message}
                    label="Date Received"
                    regFieldName="date_payment_received"
                    type="date"
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.amount_paid?.message}
                    label="Amount Paid"
                    regFieldName="amount_paid"
                    placeholder="Amount Paid"
                    type="number"
                  />

                  <FormHookSelect
                    register={register}
                    error={errors?.amount_paid_currency?.message}
                    label="Currency"
                    regFieldName="amount_paid_currency"
                    options={["JMD", "USD", "Pound", "Euro"]}
                    fieldClassName="form-control  max-240"
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.denominations_received?.message}
                    label="Denominations Received"
                    regFieldName="denominations_received"
                    placeholder="Denominations Received"
                  />

                  <Break />

                  <FormHookFileUploadHandler
                    error={errors?.payment_applicant_signature?.message}
                    register={register}
                    watch={watch}
                    label="Upload Applicant's Signature"
                    accept=".jpg, .jpeg, .png"
                    regFieldName="payment_applicant_signature"
                    fieldClassName="field-block-control max-250"
                    parentClass="individual-field "
                    isPreviewable={false}
                    isRequired={false}
                    removeFile={(id) => setValue(id, null)}
                    setValue={setValue}
                  ></FormHookFileUploadHandler>

                  <SignaturePad
                    isDisabled={props.isDisabled || props.isPreview}
                    currentValue={signaturePadValue}
                    setSignatureValue={(id, base64) => setValue(id, base64)}
                    id="payment_signature_draw_path"
                    label="Or Draw Your Signature Below"
                  />
                </div>

                <div className="d-flex-wrap justify-content-between">
                  <button
                    onClick={() => {
                      setCurrentStep(2);
                    }}
                    className="mt-2 btn btn-custom-warning"
                  >
                    Back
                  </button>

                  <button
                    onClick={async () => {
                      if (await trigger()) {
                        const values = getValues();
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            payment_details: {
                              ...paymentInfo,
                              ...values,
                              payment_list: totalPaymentInfoList,
                              amount_due: totalPayment,
                              payment_method,
                            },
                          };
                        });
                        nextAction();
                      }
                    }}
                    style={{ fontWeight: "600" }}
                    className="mt-2 btn btn-danger"
                  >
                    Proceed Further
                  </button>
                </div>
              </div>

              {BankingInformation}
            </div>
          )}

        {currentStep === 3 && payment_method === CASH_NFA_OFFICE && (
          <div className="d-flex-wrap justify-content-center">
            <div
              className="mt-2"
              style={{
                padding: "10px 10px ",
                border: "0.5px solid #444",
                maxWidth: "700px",
              }}
            >
              <div className="d-flex-wrap flex-spacing-10">
                {CREDIT_CARD_SVG(60, 60)}

                <div className="pt-1">
                  <div>
                    <b>{payment_method}</b>
                  </div>
                  <div>{PaymentTypeMetaData[CASH_NFA_OFFICE]?.desc}</div>
                </div>
              </div>

              <div className="d-flex-wrap flex-row justify-content-between">
                <div>
                  <b>{applicationConstants?.[appType]?.feeHeader}</b> -{" "}
                  <span style={{ fontSize: "20px" }}>JMD$ {totalPayment}</span>
                </div>
              </div>

              <div>
                <b>
                  If you have paid prior to submitting the application, please
                  input your Receipt # and Date of Payment
                </b>
              </div>

              <div className="d-flex-wrap flex-spacing-10">
                <FormHookInput
                  register={register}
                  error={errors?.receipt_number?.message}
                  label="Receipt #"
                  regFieldName="receipt_number"
                  placeholder="Receipt #"
                  isRequired={false}
                />

                <FormHookInput
                  register={register}
                  error={errors?.date_of_payment_cash_nfa?.message}
                  label="Date of Payment"
                  regFieldName="date_of_payment_cash_nfa"
                  placeholder="Date of Payment"
                  type="date"
                  isRequired={false}
                />
              </div>

              <div className="d-flex-wrap justify-content-between">
                <button
                  onClick={() => {
                    setCurrentStep(2);
                  }}
                  className="mt-2 btn btn-custom-warning"
                >
                  Back
                </button>

                <button
                  onClick={async () => {
                    if (await trigger()) {
                      const values = getValues();
                      setSelectedMap((map) => {
                        return {
                          ...map,
                          payment_details: {
                            ...paymentInfo,
                            ...values,
                            payment_list: totalPaymentInfoList,
                            amount_due: totalPayment,
                            payment_method,
                          },
                        };
                      });
                      nextAction();
                    }
                  }}
                  style={{ fontWeight: "600" }}
                  className="mt-2 btn btn-danger"
                >
                  Proceed Further
                </button>
              </div>
            </div>

            {BankingInformation}
          </div>
        )}
      </div>
      <SimpleNavigator
        prevValue="Previous"
        previousAction={previousAction}
        previous
        middle
        middleValue={saveAndContinueLaterText}
        middleAction={async () => {
          saveAndContinueLater({
            toCloseApp: true
          })
        }}
      ></SimpleNavigator>

      <div style={{ position: "fixed", left: "30%", top: "30%" }}>
        {paymentStatus === "processing" && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "#00b7fd",
              color: "white",
              width: "500px",
              borderRadius: "50%",
              height: "500px",
              fontSize: "40px",
              fontWeight: "600",
            }}
          >
            Processing Payment...
          </div>
        )}

        {paymentStatus === "paymentSuccessful" && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "#00b7fd",
              color: "white",
              width: "500px",
              borderRadius: "50%",
              height: "500px",
              fontSize: "40px",
              fontWeight: "600",
            }}
          >
            Payment Successful
          </div>
        )}

        {paymentStatus === "sendingToAccounts" && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "#00b7fd",
              color: "white",
              width: "500px",
              borderRadius: "50%",
              height: "500px",
              fontSize: "40px",
              fontWeight: "600",
            }}
          >
            Generating Receipt
          </div>
        )}

        {paymentStatus === "sentToAccounts" && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "#00b7fd",
              color: "white",
              width: "500px",
              borderRadius: "50%",
              height: "500px",
              fontSize: "40px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Receipt Generated Successfully
          </div>
        )}
      </div>
    </div>
  );
}

export default Payment;
