import React, { useState, useEffect, useContext, useRef } from "react";

import SimpleNavigator from "../SimpleNavigator";
import { getBase64, saveAndContinueLaterText } from "../../utils/utils";
import { useHistory } from "react-router-dom";
import { getData, postData } from "../../services/service-call";
import { ROUTES } from "../../config/index";
import "../../../assets/styles/common-styles.css";
import axios from "axios";
import store from "../../redux/store";
import { setCurrentDocuments } from "../../redux/actions/documents";
import { useDispatch, useSelector } from "react-redux";
import { APP_SUBMISSION_CONFIG } from "../../config/application-submission-config";
import { alertService } from "../../_services/alert.service";
import { find, groupBy } from "lodash";
import { FLISContext } from "../../context/FLISContext";
import { getDocuments, groupDocsInfo } from "../../config/documents";
import DocumentUploader from "./DocumentUploader";
import SupportingDocumentWithoutDefinedTypes from "./SupportingDocumentWithoutDefinedTypes";
import _ from "lodash";

//todo more actions and disable ness depending upon diff use case

function SupportingDocuments(props) {
  const history = useHistory();

  const { selectedMap } = useContext(FLISContext);

  let state = store.getState();

  let appType = useSelector((state) => {
    return state.currentTransaction.appType;
  });

  const temp_trans_id = useSelector((state) => state.currentTransaction.temp_trans_id);

  let appDetails = APP_SUBMISSION_CONFIG[appType] || {};

  let routingDetails = appDetails.steps.filter((step) => {
    if (step.currentStep === history.location.pathname) {
      return true;
    }
    return false;
  })[0];

  let documentsToUpload = getDocuments(appType, selectedMap);

  documentsToUpload = documentsToUpload?.filter(doc => {
    
    if(!doc.condition) return true;
    if(doc.condition(selectedMap)) return true;

    return false;
  })
  // documentsToUpload = documentsToUpload.map(doc => {
  //     // eslint-disable-next-line react-hooks/rules-of-hooks
  //     doc.ref = useRef();
  //     return doc;
  // });

  let [documents, setDocuments] = useState(documentsToUpload ?? []);

  const nextAction = () => {
    // store.dispatch(setCurrentDocuments(
    //     documents?.filter((document) => {
    //         return document.isUploaded
    //     })
    // ));

    let isAllUploaded = true;
    const groupedDocs = groupBy(documents, 'group_key');
    for(let i=0;i<documents.length;i++) {
      if(documents[i].isMandatory && !documents[i].isUploaded) {
        isAllUploaded = false;
        break;
      }
    }

    let isAllUploadedforgroup = true;
    const groupedKeys = Object.keys(groupedDocs);
    for(let i=0;i<groupedKeys.length;i++) {
      let grouped_key = groupedKeys[i];
      if(!groupDocsInfo[grouped_key]?.isMandatory) {
        continue;
      }

      if(groupDocsInfo[grouped_key]?.atleastOne) {
        const found = groupedDocs[grouped_key].some((doc, index) => {
          return doc.isUploaded
        });

        if(!found) isAllUploadedforgroup = false;
      }

      if(groupDocsInfo[grouped_key]?.all) {
        const found = groupedDocs[grouped_key].every((doc, index) => {
          return doc.isUploaded
        });

        if(!found) isAllUploadedforgroup = false;
      }

    }

    if(isAllUploaded && isAllUploadedforgroup) {
      history.push(routingDetails.next);
    } else {
      alertService.warn("All Documents must be uploaded before proceeding ", {
        autoClose: true,
        keepAfterRouteChange: true,
      });
    }
    
  };

  const previousAction = () => {
    history.push(routingDetails.previous);
  };

  const selectFile = async (event, index = 0) => {
    // single document code below
    const file = event.target.files[0];
    if (!file) return;
    documents[index].file = file;

    // multi document code below
    // let files = event.target.files;
    // if (!files)
    //     return

    // files = Array.from(files).map((file) => file);

    // setDocuments((documents) => {
    //     let updatedDocuments = [];
    //     documents.map((document, i) => {
    //         if (index === i) {
    //             files.map((file) => {
    //                 updatedDocuments.push(Object.assign({}, document, {
    //                     isUploaded: false,
    //                     file
    //                 }))
    //             });
    //         }
    //         updatedDocuments.push(document);
    //     });
    //     return updatedDocuments;//.concat(initialDocument);
    // });

    uploadFile(index);
  };


  const uploadFile = async (index) => {
    // return;
    if (documents[index].isUploaded) {
      alertService.warn("Document already uploaded", {
        autoClose: true,
        keepAfterRouteChange: true,
      });
      return;
    }
    const file = documents[index]?.file;
    if (!file?.name) return;
    const { base64: content, contentType } = await getBase64(
      documents[index].file
    );

    const lastIndexofDot = file.name?.lastIndexOf('.');
    const extension = file.name?.substring(lastIndexofDot)?.toLowerCase();

    // validation for valid file extension
    if(documents[index]?.accept?.toLowerCase().indexOf(extension) === -1) {
      alertService.warn(`Only ${documents[index]?.accept} files are accepted`, {
        autoClose: true,
        keepAfterRouteChange: true,
      });
      documents[index].file = {};
      return;
    }

    // validation end for valid file extension

    // validation for valid file size should be greater than 0kb and less then 20mb 
      if(documents?.[index]?.file?.size <= 0 || documents?.[index]?.file?.size > 20 * 1024 * 1024) {
        alertService.warn(`File size should be greater than 0 KB and less then 20 MB`, {
          autoClose: true,
          keepAfterRouteChange: true,
        });

        // also clear the file input since we are not accepting it

        documents[index].file = {};
        return;
      } 

    // validation end for valid file size 
    
    try {
      const result = await postData({
        url: ROUTES.documentUpload,
        body: {
          document: {
            content,
            contentType,
            name: documents[index].file.name,
            supporting_document_id: documents[index].id,
            documentType: "SUPPORTING_DOCUMENTS",
          },
          temp_trans_id: state.currentTransaction.temp_trans_id,
        },
      });
      setDocuments((documents) => {
        return (
          documents?.map((document, ind) => {
            if (index === ind) {
              document.isUploaded = true;
              document.id = result.data.id;
            }
            return document;
          }) || []
        );
      });
      alertService.success("Document uploaded successfully", {
        autoClose: true,
        keepAfterRouteChange: true,
      });
      return {
        isUploaded: true,
        id: result.data.id,
      };
    } catch (err) {
      alertService.error("Document could not uploaded successfully", {
        autoClose: true,
        keepAfterRouteChange: true,
      });
      return {
        isUploaded: false,
      };
    }
  };

  const removeDocument = async (index) => {
    const { id, isUploaded } = documents[index];

    if (!isUploaded) {
      setDocuments((documents) => {
        return (
          documents?.map((doc, i) => {
            if (i === index) {
              doc.file = {};
              doc.isUploaded = false;
              // ref.current.value = null;
            }

            return doc;
          }) || []
        );
      });
      return;
    }
    const result = await postData({
      url: `${ROUTES.removeDocument}${id}`,
    });
    alertService.success("Document removed successfully", {
      autoClose: true,
      keepAfterRouteChange: true,
    });
    getAllDocumentsUploaded();
  };

  const getAllDocumentsUploaded = () => {
    // here we are mapping the uploaded documents with the required configured documents to show
    // for returning user to show them what all documents is uploaded

    getData({
      url: `${ROUTES.getAllDocuments}${appType}/${state.currentTransaction.temp_trans_id}`,
    }).then((data) => {
      let { documents: uploadedDocuments, supportingDocuments } = data;

      supportingDocuments = _.cloneDeep(documentsToUpload);

      let newDocuments = supportingDocuments
        ?.map((document, index) => {
          const foundDoc = find(uploadedDocuments, {
            supporting_document_id: document.id,
          });

          if (foundDoc) {
            document.isUploaded = true;
            document.file = {};
            document.file.name = foundDoc.file_name;
            document.id = foundDoc.id;
            return document;
          } else {
            document.isUploaded = false;
            document.file = {};
            return document;
          }
        })
        ?.filter((e) => e);
      setDocuments(newDocuments);
    });
  };

  useEffect(() => {
    getAllDocumentsUploaded();
    alertService.info("Please upload the supporting documents", {
      autoClose: true,
      keepAfterRouteChange: false,
    });
  }, []);

  let groupHead = {};

  const shouldShowHeading = (document, head) => {
    if(!document?.group_key) return false;
    if(!head[document.group_key]) head[document.group_key] = true;
    else return false;

    return true;
  }

  // <button className="btn btn-primary" type="button" id="clickImage" onClick={() => inputFileUpload.click()} value="Upload">Select files...</button>

  // <input type="file" hidden ref={input => inputFileUpload = input} multiple onChange={(event) => selectFile(event, index)}></input>

  return (
    <div>
      <div className="shadow-sm p-3  bg-light rounded table-responsive left-align">
        <div className="head-text">Supporting Documents</div>

        <div className="d-flex flex-row justify-content-between mb-1 mt-1">
          {/* <button className="btn btn-info" type="button" id="clickImage" onClick={() => inputFileUpload.click()} value="Upload">Select files...</button>

                    <input type="file" hidden ref={input => inputFileUpload = input} multiple onChange={(event) => selectFile(event)}></input>

                    <button className="btn btn-success ml-2" type="button" id="uploadAll" value="Upload">Upload All</button> */}
        </div>

        {!!documents?.length && (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Document</th>

                <th>Uploaded Document</th>
                <th>Select</th>
                <th>Upload</th>
                <th>Upload Status</th>
              </tr>
            </thead>
            <tbody>
              {documents?.map((document, index) => {
                const showHead = shouldShowHeading(document, groupHead);
                return (
                  <>
                  {
                    document?.group_key && showHead && <b style={{fontSize: '18px'}}>{groupDocsInfo[document.group_key].name}</b>
                  }
                  <tr key={index}>
                    <td>
                      <b>{document.name} {document.isMandatory ? "" : " (Optional) "} </b>
                      
                     
                      {/* {
                        document.key === 'current_picture_of_applicant' && 
                        <>
                         <div>Sample Photo </div>
                         <img
                         alt="Sample requirement"
                        src={"./images/photo_requirement.png"}
                        rel="noreferrer"
                        width={"85px"}
                        height={"85px"}>
                        </img>
                         </>
                      
                      } */}
                      
                    </td>
                    <td>{document.file?.name} </td>

                    <td>
                      <input
                        type="file"
                        ref={document.ref}
                        onChange={(event) => selectFile(event, index)}
                        accept={document.accept}
                      />
                    
                      {
                        document.key === 'current_picture_of_applicant' && 
                        <a
                        className="text-s-bold failure-text"
                        href={"./forms/PHOTO_REQUIREMENTS_IRIEFINS.pdf"}
                        rel="noreferrer"
                        target="_blank"
                      >
                        (View Instructions!)
                      </a>
                      }

                      {document.file?.name && (
                        <button
                          onClick={() => {
                            removeDocument(index);
                          }}
                          className="btn btn-sm btn-danger ml-1"
                        >
                          Remove
                        </button>
                      )}
                    </td>

                    <td>
                      {" "}
                      <button
                        onClick={() => uploadFile(index)}
                        className="btn  btn-sm btn-warning"
                      >
                        Upload
                      </button>{" "}
                    </td>

                    <td
                      className={
                        document.isUploaded ? "success-text" : "failure-text"
                      }
                    >
                      <b>{document.isUploaded ? "Uploaded" : "Not Uploaded"}</b>{" "}
                    </td>
                  </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        )}

        <div>
          <ul>
            <li>
              For Current Picture of Applicant click{" "}
              <a
                href={"./forms/PHOTO_REQUIREMENTS_IRIEFINS.pdf"}
                rel="noreferrer"
                target="_blank"
              >
                here {" "}
              </a>
              for Instructions
            </li>
            <li>
              Note if you do not have a picture identification, you must submit
              a Birth Certificate with a certified passport-sized photo. If you
              do not have a Birth Certificate, a Statutory Declaration of Date
              of Birth must be submitted.
            </li>
            <li>
              Foreign applicant's photographs do not require certification if a
              passport identification has been submitted.
            </li>
            <li>
              For business applicants, business registration documents are
              required.
            </li>
          </ul>
          {/* Note:- If no ID, Birth Certificate with certified passport-sized photos and/or a letter from a JP stating 
knowledge of applicant. Foreign applicants’ photographs do not require certification once passport
presented. (For business applicants, business registration documents are required.) */}
        </div>

        <SupportingDocumentWithoutDefinedTypes
          classes={null}
          shouldDisplayNavBar={false}
          shouldHaveNavigator={false}
        />
      </div>
      <SimpleNavigator
        next
        nextAction={nextAction}
        previousAction={previousAction}
        previous
        middle
        middleValue={saveAndContinueLaterText}
        middleAction={async () => {
          const result = await postData({
            url: `${ROUTES.saveApplication}?app_type=${appType}`,
            body: {
              ...selectedMap,
              temp_trans_id: temp_trans_id,
              app_type: appType,
              launchCase: false,
            },
          });

          if (result.statusCode === 200) {
            alertService.success("Application saved successfully", {
              autoClose: true,
              keepAfterRouteChange: true,
            });

            history.replace("/application-types");
          } else {
            alertService.error("Error occured while saving application", {
              autoClose: true,
              keepAfterRouteChange: true,
            });
          }
        }}
      ></SimpleNavigator>
    </div>
  );
}

export default SupportingDocuments;
