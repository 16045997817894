import { ARTISANAL, ARTISANAL_FISHING, CARRIER, CARRIER_FISHING, COMMERCIAL, COMMERCIAL_FISHING, COMMERCIAL_LOBSTER, CONCH, CONCH_TYPE_OF_VESSEL, ENTERTAINMENT, FIN_FISH, FOREIGN, INDUSTRIAL, INDUSTRIAL_FISHING, IRISH_MOSS_SEA_WEEDS, LOCAL, MOTORISED_FINFISH, NON_CARRIER, NON_MOTORISED, OTHER_FISHER, RECREATIONAL, RECREATIONAL_FISHING, REGULAR_LOBSTER, SEA_CUCUMBER, SEA_CUCUMBER_TYPE_OF_VESSEL, SEA_MOSS, SPINY_LOBSTER, SPORTS_CHARTER, SUBSISTENCE, TOURNAMENT } from "../components/DataSource";
import { CASH_SVG, CREDIT_CARD_SVG } from "../components/ImageDataSource";
import { COMPANY_AUTHORIZATION, INDIVIDUAL_AUTHORIZATION, VESSEL_AUTHORIZATION } from "../containers/AuthorizationLicence/AuthorizationDataSource";
import { LETTER_TO_JFCU, VERIFICATION_LETTERS, LETTER_TO_MINISTRY_LABOUR, LETTER_TO_PICA, LETTER_TO_JAMAICA_CUSTOMS, VERIFICATION_LETTER_SEIZURE_OF_BOAT, LETTER_OF_NO_OBJECTION, LETTER_TO_CONSULATE_EMBASSY, VERIFICATION_LETTER_FISH_FARMERS, LETTER_TO_MINISTRY_NATIONAL_SECURITY, SUBSTITUTE_ID_CARD } from "../containers/GeneralAdministration/GeneralAdministrationDataSource";
import { INDUSTRIAL_VESSEL_CREW_MEMBER, MORANT_PEDRO, SPORTS_TOURNAMENT, TEMPORARY_PERMIT, TOURNAMENT_HOSTING, RECREATIONAL_FISHING as RECREATIONAL_FISHING_PERMIT, OTHER } from "../containers/PermitLicence/PermitDataSource";



export const VESSEL_INSPECTION = 'Vessel Inspection';


const commercialArtisanalCommon = {
  [REGULAR_LOBSTER]: [
    {
      name: REGULAR_LOBSTER,
      value: 5000,
    },
  ],
  [COMMERCIAL_LOBSTER]: [
    {
      name: COMMERCIAL_LOBSTER,
      value: 20000,
    },
  ],
  [CONCH_TYPE_OF_VESSEL]: [
    {
      name: CONCH_TYPE_OF_VESSEL,
      value: 7000,
    },
  ],
  [SEA_CUCUMBER_TYPE_OF_VESSEL]: [
    // added from the email - would fall under other fishery
    {
      name: SEA_CUCUMBER_TYPE_OF_VESSEL,
      value: 3500,
    },
  ],
  [SEA_MOSS]: [
    {
      name: SEA_MOSS,
      value: 3500,
    },
  ],
  [OTHER_FISHER]: [
    {
      name: OTHER_FISHER,
      value: 3500,
    },
  ],
};

export const fees = {
  APPLICATION_FOR_LICENCE: {
    [COMMERCIAL_FISHING]: {
      [ARTISANAL]: {
        General: {
          [LOCAL]: [
            {
              name: "General Fishing",
              value: 1200,
            },
          ],
          [FOREIGN]: [],
        },
        // [SPINY_LOBSTER]: {
        //   [LOCAL]: [
        //     {
        //       name: SPINY_LOBSTER,
        //       value: 3500,
        //     },
        //   ],
        //   [FOREIGN]: [
        //     {
        //       name: SPINY_LOBSTER,
        //       value: 6500,
        //     },
        //   ],
        // },
        [CONCH]: {
          [LOCAL]: [
            {
              name: "Conch",
              value: 2000,
            },
          ],
          [FOREIGN]: [
            {
              name: "Conch",
              value: 6500,
            },
          ],
        },
        [SEA_CUCUMBER]: {
          [LOCAL]: [
            {
              name: SEA_CUCUMBER,
              value: 3500,
            },
          ],
          [FOREIGN]: [
            {
              name: SEA_CUCUMBER,
              value: 6500,
            },
          ],
        },
        [IRISH_MOSS_SEA_WEEDS]: {
          [LOCAL]: [
            {
              name: IRISH_MOSS_SEA_WEEDS,
              value: 3500,
            },
          ],
          [FOREIGN]: [
            {
              name: IRISH_MOSS_SEA_WEEDS,
              value: 3500,
            },
          ],
        }
      },
      [INDUSTRIAL]: {
        General: {
          [LOCAL]: [
            {
              name: "General Fishing",
              value: 3500,
            },
          ],
          [FOREIGN]: [
            {
              name: "General Fishing",
              value: 6000,
            },
          ],
        },
        [SPINY_LOBSTER]: {
          [LOCAL]: [
            {
              name: "Spiny Lobster",
              value: 3500,
            },
          ],
          [FOREIGN]: [
            {
              name: "Spiny Lobster",
              value: 6500,
            },
          ],
        },
        [CONCH]: {
          [LOCAL]: [
            {
              name: "Conch",
              value: 3500,
            },
          ],
          [FOREIGN]: [
            {
              name: "Conch",
              value: 6000,
            },
          ],
        },
        [SEA_CUCUMBER]: {
          [LOCAL]: [
            {
              name: SEA_CUCUMBER,
              value: 3500,
            },
          ],
          [FOREIGN]: [
            {
              name: SEA_CUCUMBER,
              value: 6500,
            },
          ],
        },
        [IRISH_MOSS_SEA_WEEDS]: {
          [LOCAL]: [
            {
              name: IRISH_MOSS_SEA_WEEDS,
              value: 3500,
            },
          ],
          [FOREIGN]: [
            {
              name: IRISH_MOSS_SEA_WEEDS,
              value: 3500,
            },
          ],
        }
      },
    },
    [RECREATIONAL_FISHING]: {
      General: {
        [LOCAL]: [
          {
            name: "General Fishing",
            value: 3500,
          },
        ],
        [FOREIGN]: [
          {
            name: "General Fishing",
            value: 6000,
          },
        ],
      },
      [IRISH_MOSS_SEA_WEEDS]: {
        [LOCAL]: [
          {
            name: IRISH_MOSS_SEA_WEEDS,
            value: 3500,
          },
        ],
        [FOREIGN]: [
          {
            name: IRISH_MOSS_SEA_WEEDS,
            value: 3500,
          },
        ],
      }
      //   [SPINY_LOBSTER]: {
      //     [LOCAL]: [],
      //     [FOREIGN]: [],
      //   },
      //   [CONCH]: {
      //     [LOCAL]: [],
      //     [FOREIGN]: [],
      //   },
      //   [SEA_CUCUMBER]: {
      //     [LOCAL]: [],
      //     [FOREIGN]: [],
      //   },
    },
  },
  APPLICATION_FOR_FISHING_VESSEL: {
    [COMMERCIAL]: {
      [ARTISANAL_FISHING]: {
        // [NON_MOTORISED]: [
        //   {
        //     name: NON_MOTORISED,
        //     value: 1000,
        //   },
        //   {
        //     name: VESSEL_INSPECTION,
        //     value: 200,
        //   },
        // ],
        // [MOTORISED_FINFISH]: [
        //   {
        //     name: MOTORISED_FINFISH,
        //     value: 2200,
        //   },
        //   {
        //     name: VESSEL_INSPECTION,
        //     value: 800,
        //   },
        // ],

        [`${NON_MOTORISED}_INSPECTION`]: [
          {
            name: VESSEL_INSPECTION,
            value: 200,
          },
        ],

        [`${MOTORISED_FINFISH}_INSPECTION`]: [
          {
            name: VESSEL_INSPECTION,
            value: 800,
          },
        ],
        [NON_MOTORISED]: {
          [FIN_FISH]: [
            {
            name: FIN_FISH,
            value: 1000,
          }],
          ...commercialArtisanalCommon,
        },
        [MOTORISED_FINFISH]: {
          [FIN_FISH]: [
            {
              name: FIN_FISH,
              value: 2200,
            },
          ],
          ...commercialArtisanalCommon,
        },
        // // fee for type of carrier 
        [`${CARRIER}_CARRIER`]: [
          {
            name: CARRIER,
            value: 10000,
          },
        ],
        [`${NON_CARRIER}_CARRIER`]: []

      },
      [INDUSTRIAL_FISHING]: {
        "Spiny Lobster": [
          {
            name: "Application Fee – Industrial Motor Fishing Vessel",
            value: 125000,
          },
          {
            name: "Industrial Motor Fishing Vessel Licence Fee",
            value: 250000,
          },
        ],
        "Fin Fish": [
          {
            name: "Fin Fish",
            value: 35000,
          },
        ],
        "Sea Cucumber": [
          // added from the email - would fall under other fishery
          {
            name: "Sea Cucumber",
            value: 10000,
          },
        ],
        Other: [
          {
            name: "Other Fishery",
            value: 10000,
          },
        ],
        Conch: [
          {
            name: "Conch",
            value: 35000,
          },
        ],
        "Vessel Inspection": {
          name: VESSEL_INSPECTION,
          value: 1000,
        },
      },
      [CARRIER_FISHING]: {
        // removed as Carrier Fishing is always Artisanal Carrier
        // "Industrial Carrier" : {
        //     "Fin Fish": [
        //         {
        //             name: "Fin Fish",
        //             value: 35000
        //         }
        //     ],
        //     "Lobster": [
        //         {
        //             name: "Application Fee – Industrial Motor Fishing Vessel",
        //             value: 125000
        //         },
        //         {
        //             name: "Industrial Motor Fishing Vessel Licence Fee",
        //             value: 250000
        //         }
        //     ],
        //     "Conch": [
        //         {
        //             name: "Conch",
        //             value: 35000
        //         }
        //     ],
        //     "Sea Cucumber": [],

        // },
        "Artisanal Carrier": {
          "Carrier Vessel > 50ft- operating with Industrial Vessel": [
            {
              name: "Carrier Vessel (more than 50ft length) operating with industrial vessel",
              value: 75000,
            },
            {
              name: VESSEL_INSPECTION,
              value: 900,
            },
          ],
          "Carrier Vessel < 50ft- operating with Industrial Vessel": [
            {
              name: "Carrier Vessel (less than 50ft. length) operating with industrial vessel",
              value: 50000,
            },
            {
              name: VESSEL_INSPECTION,
              value: 900,
            },
          ],
          "Independent Carrier Vessel >50 ft) not more than 4000 lbs whole lobster per trip":
            [
              {
                name: "Independent Carrier Vessel (more than 50ft. length) no more than 4,000lbs whole lobster per trip",
                value: 75000,
              },
              {
                name: VESSEL_INSPECTION,
                // changed from 500 to 900 after talking with Elena
                value: 900,
              },
            ],
          "Independent Carrier Vessel <50 ft) not more than 2000 lbs whole lobster per trip":
            [
              {
                name: "Independent Carrier Vessel (less than 50ft. length); no more than 2,000lbs whole lobster per trip",
                value: 50000,
              },
              {
                name: VESSEL_INSPECTION,
                // changed from 500 to 900 after talking with Elena
                value: 900,
              },
            ],
        },
        // removed after seeing in doc
        // "Vessel Inspection": [
        // {
        //     name: VESSEL_INSPECTION,
        //     value: 500
        // }
        // ]
      },
    },
    [RECREATIONAL]: {
      [SPORTS_CHARTER]: {
        "Decked Vessel (Including yacht)": [
          {
            name: "Decked Vessel (Including yacht)",
            value: 20000,
          },
          {
            name: VESSEL_INSPECTION,
            value: 1000,
          },
        ],
        "Non-Decked (Motorized)": [
          {
            name: "Non-Decked (Motorized)",
            value: 10000,
          },
          {
            name: VESSEL_INSPECTION,
            value: 500,
          },
        ],
        "Non-Decked (Non -motorized)": [
          {
            name: "Non-Decked (Non -motorized)",
            value: 7000,
          },
          {
            name: VESSEL_INSPECTION,
            value: 500,
          },
        ],
      },
      [ENTERTAINMENT]: {
        "Decked Vessel (Including yacht)": [
          {
            name: "Decked Vessel (Including yacht)",
            value: 10000,
          },
          {
            name: VESSEL_INSPECTION,
            value: 1000,
          },
        ],
        "Non-Decked (Motorized)": [
          {
            name: "Non-Decked (Motorized)",
            value: 5000,
          },
          {
            name: VESSEL_INSPECTION,
            value: 500,
          },
        ],
        "Non-Decked (Non -motorized)": [
          {
            name: "Non-Decked (Non -motorized)",
            value: 3000,
          },
          {
            name: VESSEL_INSPECTION,
            value: 500,
          },
        ],
        "Temporary Certificate": [
          {
            name: "Temporary Certificate",
            value: 500,
          },
        ],
      },
      [TOURNAMENT]: {
        "Decked Vessel (Including yacht)": [
          {
            name: "Decked Vessel (Including yacht)",
            value: 10000,
          },
          {
            name: VESSEL_INSPECTION,
            value: 1000,
          },
        ],
        "Non-Decked (Motorized)": [
          {
            name: "Non-Decked (Motorized)",
            value: 5000,
          },
          {
            name: VESSEL_INSPECTION,
            value: 500,
          },
        ],
        "Non-Decked (Non -motorized)": [
          {
            name: "Non-Decked (Non -motorized)",
            value: 3000,
          },
          {
            name: VESSEL_INSPECTION,
            value: 500,
          },
        ],
        "Temporary Certificate": [
          {
            name: "Temporary Certificate",
            value: 500,
          },
        ],
      },
      [SUBSISTENCE]: {
        "Decked Vessel (Including yacht)": [
          {
            name: "Decked Vessel (Including yacht)",
            value: 10000,
          },
          {
            name: VESSEL_INSPECTION,
            value: 1000,
          },
        ],
        "Non-Decked (Motorized)": [
          {
            name: "Non-Decked (Motorized)",
            value: 5000,
          },
          {
            name: VESSEL_INSPECTION,
            value: 500,
          },
        ],
        "Non-Decked (Non -motorized)": [
          {
            name: "Non-Decked (Non -motorized)",
            value: 3000,
          },
          {
            name: VESSEL_INSPECTION,
            value: 500,
          },
        ],
      },
    },
  },
  APPLICATION_FOR_PERMIT: {
    [MORANT_PEDRO]: [
      {
        name: MORANT_PEDRO,
        value: 5000,
      },
    ],
    [INDUSTRIAL_VESSEL_CREW_MEMBER]: (count) => [
      {
        name: INDUSTRIAL_VESSEL_CREW_MEMBER,
        value: 3500 * count,
      },
    ],
    [TOURNAMENT_HOSTING]: [
      {
        name: "Permit Letter",
        value: 2000,
      },
    ],
    [TEMPORARY_PERMIT]: {
      [SPORTS_TOURNAMENT]: {
        [LOCAL]: [
          {
            name: "Tournament Fishing - Sports Tournament",
            value: 1500,
          },
        ],
        [FOREIGN]: [
          {
            name: "Tournament Fishing - Sports Tournament",
            value: 3000,
          },
        ],
      },
      [RECREATIONAL_FISHING_PERMIT]: [
        {
          name: "Temporary Permit - Recreational Fishing",
          value: 500,
        },
      ],
      [OTHER]: [
        {
          name: "Temporary Permit - Other",
          value: 500,
        },
      ],
    },
  },
  APPLICATION_FOR_AQUACULTURE_FISHER: [
    // question to be sent
    {
      name: "Aquaculture Fish Farmer ID",
      value: 500,
    },
  ],
  APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL: {
    // question to be sent
  },
  APPLICATION_FOR_AQUACULTURE_FACILITY: {
    // no fee
  },
  APPLICATION_FOR_AUTHORIZATION: {
    [INDIVIDUAL_AUTHORIZATION]: [
      {
        name: "Authorization - Individual",
        value: 500,
      },
    ],
    [COMPANY_AUTHORIZATION]: [
      {
        name: "Authorization - Company",
        value: 2000,
      },
    ],
    [VESSEL_AUTHORIZATION]: [
      {
        name: "Authorization - Vessel",
        value: 2000,
      },
    ],
  },
  APPLICATION_QUOTA_FISHING: {
    // document for fee to be sent each year it will be different
  },
  APPLICATION_FOR_DECLARATION: {
    // question to be sent
  },
  APPLICATION_FOR_LANDING_CATCH_DECLARATION: {
    // fee not applicable
  },
  APPLICATION_FOR_GENERAL_ADMINISTRATION: {
    General: [
      {
        name: "Letters & Other Services Fee",
        value: 500,
      },
    ],
    [VERIFICATION_LETTERS]: [
      {
        name: VERIFICATION_LETTERS,
        value: 500,
      },
    ],
    [LETTER_TO_JFCU]: [
      {
        name: LETTER_TO_JFCU,
        value: 500,
      },
    ],
    [LETTER_TO_MINISTRY_LABOUR]: [
      {
        name: LETTER_TO_MINISTRY_LABOUR,
        value: 1000,
      },
    ],
    [LETTER_TO_PICA]: [
      {
        name: LETTER_TO_PICA,
        value: 1000,
      },
    ],
    [LETTER_TO_JAMAICA_CUSTOMS]: [
      {
        name: LETTER_TO_JAMAICA_CUSTOMS,
        value: 2000,
      },
    ],
    [VERIFICATION_LETTER_SEIZURE_OF_BOAT]: [
      {
        name: VERIFICATION_LETTER_SEIZURE_OF_BOAT,
        value: 500,
      },
    ],
    [LETTER_OF_NO_OBJECTION]: [
      {
        name: LETTER_OF_NO_OBJECTION,
        value: 2000,
      },
    ],
    [LETTER_TO_CONSULATE_EMBASSY]: [
      {
        name: LETTER_TO_CONSULATE_EMBASSY,
        value: 500,
      },
    ],
    [VERIFICATION_LETTER_FISH_FARMERS]: [
      {
        name: VERIFICATION_LETTER_FISH_FARMERS,
        value: 500,
      },
    ],
    [LETTER_TO_MINISTRY_NATIONAL_SECURITY]: [
      {
        name: LETTER_TO_MINISTRY_NATIONAL_SECURITY,
        value: 1000,
      },
    ],
    [SUBSTITUTE_ID_CARD]: [
      {
        name: SUBSTITUTE_ID_CARD,
        value: 500,
      },
    ]
  },
  APPLICATION_FOR_FISHER_WORKER_ID: {
    // is same as fisher
  },
};

export const VesselInspectionFee = {
  // to be checked on how this will get mapped as it is not symetric
  APPLICATION_FOR_LICENCE: {},
  APPLICATION_FOR_FISHING_VESSEL: {},
  APPLICATION_FOR_PERMIT: {},
  APPLICATION_FOR_AQUACULTURE_FISHER: {},
  APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL: {},
  APPLICATION_FOR_AQUACULTURE_FACILITY: {},
  APPLICATION_FOR_AUTHORIZATION: {},
  APPLICATION_QUOTA_FISHING: {},
  APPLICATION_FOR_DECLARATION: {},
  APPLICATION_FOR_LANDING_CATCH_DECLARATION: {},
  APPLICATION_FOR_GENERAL_ADMINISTRATION: {},
  APPLICATION_FOR_FISHER_WORKER_ID: {},
};


export const CREDIT_CARD = "Credit/Debit Card";
export const WIRE_TRANSFER = "Wire/Bank Transfer";
export const DIRECT_DEBIT = "Direct Debit"; // not present in BPM
export const CHEQUE = "Cheque";
export const CASH_NFA_OFFICE = "Cash at NFA Office";
export const CASH_FIELD_OFFICER = "Cash – Field Officer";

export const PaymentTypes = [
    {
      type: CREDIT_CARD,
      description: "Pay directly through Credit Card",
      image: (theme) => CREDIT_CARD_SVG(50, 40, theme),
      // isDisabled: false
    },
    {
      type: WIRE_TRANSFER,
      description: "Pay via Wire Transfer",
      image: (theme) => CREDIT_CARD_SVG(50, 40, theme),
    },
    // {
    //   type: DIRECT_DEBIT,
    //   description: "Pay directly through Direct Debit",
    //   image: (theme) => CREDIT_CARD_SVG(50, 40, theme),
    // },
    {
      type: CHEQUE,
      description: "Pay directly through Cheque",
      image: (theme) => CREDIT_CARD_SVG(50, 40, theme),
    },
    {
      type: CASH_NFA_OFFICE,
      description: "Pay directly at an NFA Office to a Cashier",
      image: (theme) => CREDIT_CARD_SVG(50, 40, theme),
    },
    // {
    //   type: CASH_FIELD_OFFICER,
    //   description:
    //     "Pay directly by Cash to Field officer at one of the Fisheries department office",
    //   image: CASH_SVG,
    // },
  ];

  export const PaymentTypeMetaData = {
    [CASH_NFA_OFFICE]: {
        desc: "Pay directly at an NFA Office"
    },
    [CREDIT_CARD]: {
        desc:  "Pay directly through Credit/Debit Card"
    },
    [WIRE_TRANSFER]: {
        desc: "Pay via Wire Transfer"
    },
    [DIRECT_DEBIT]: {
        desc: "Pay directly through Direct Debit"
    },
    [CHEQUE]: {
      desc: "Pay through Cheque"
    }
  }


  export const fisherIdOptions = [
    {
      value: "In-Office",
      label: "JMD 500 - In Office",
    },
    {
      value: "In-Field",
      label: "JMD 600 - In Field",
    },
  ];

  export const fisherIdOptionsMapping = {
    "In-Office": {
        name: "In Office",
        value: 500
    },
    "In-Field": {
        name: "In Field",
        value: 600
    }
  }