import React, { useContext, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import "../../../assets/styles/form.css";
import HorizontalLine from "../../components/HorLine";
import SimpleNavigator from "../../components/SimpleNavigator";
import { postData } from "../../services/service-call";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import { alertService } from "../../_services";

import {
  BASE_LOCATION_INLAND,
  BASE_LOCATION_PORT,
  Break,
  Declarations,
  InlandList,
  RenewalTypeOptionsList,
  baseLocations,
  emailPattern,
  fishingAreaLocations,
  formatPhoneNumber,
  getMaxDateForAtleastYear,
  individualYesDocList,
  isRequiredCompany,
  isRequiredIndividual,
  regionListForInland,
  regionParishList,
  regionParishPort,
  telephonePattern,
  wrapMessageforRequired
} from "../../components/DataSource";

import { typeOfLicence, typeOfTransactions, typeOfSpecies, gearTypeToBeLicenced, applicantRoleInFisheries, TOURNAMENT_HOSTING, applicantRoleAsCrew, MORANT_PEDRO, permitRequiredFor, INDUSTRIAL_VESSEL_CREW_MEMBER, TEMPORARY_PERMIT, fishingAreaPermitLocations } from "./PermitDataSource";
import { FormHookCheckbox, FormHookInput, FormHookRadioInput, FormHookSelect, FormHookTextArea } from "../../components/FormHook/ReactFormHookFields";
import { RENEWAL, countries } from "../AuthorizationLicence/AuthorizationDataSource";
import { emailNotValid, telephoneNotValid } from "../../utils/messages";
import { FLISContext } from "../../context/FLISContext";
import { FormHookFileUploadHandler } from "../../components/FormHook/FormHookDocument";
import { SignaturePad } from "../../components/SignatureCanvas";
import { fillInAllTheFields, getPreviewHeading, saveAndContinueLaterText, scrollToTop } from "../../utils/utils";
import GeneralDeclarations from "../GeneralDeclaration";
import { TournamentInformation } from "./TournamentInformation";
import { CustomTextAreaContainer } from "../../components/CustomInputContainer";
import { ROUTES } from "../../config";
import { TournamentDirectorInformation } from "./TournamentDirectorInfo";
import { LocationDetails } from "./PermitLocationDetails";
import SimpleDeclaration from "../SimpleDeclaration";


export const ApplicationForPermit = (props) => {


  const {selectedMap, setSelectedMap} = useContext(FLISContext);

  const history = useHistory();
  const buttonSubmitRef = useRef();

  const transactionDetails = useSelector((state) => {
    return state.currentTransaction;
  });


  useEffect(() => {
    if (transactionDetails.temp_trans_id) {
      // dispatch(getCurrentTransaction(transactionDetails.temp_trans_id));
    }
  }, [transactionDetails.temp_trans_id]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    getValues,
    trigger,
    control
  } = useForm({
    defaultValues: selectedMap,
    mode: 'all'
  });

  React.useImperativeHandle(props.reference, () => {
    return {
      ...(props?.reference?.current || {}),
      trigger: trigger,
    };
  });

  const { errors } = formState;

  let appType = useSelector((state) => {
    return state?.currentTransaction?.appType;
  });


  const onSubmit = async (data) => {

    if (selectedForm === 1) {
      scrollToTop();
      setSelectedForm(2);
      return;
    }


    data.temp_trans_id = props.temp_trans_id;
    history.push(props.next);
  };

  const nextAction = async () => {
    const isValid = await trigger();
    const data = getValues();

    if(selectedForm === 2 && !data?.applicant_signature  && !data?.signature_draw_path){
      alertService.warn('Fill all the mandatory fields');
      return; 
    }
    

    if(isValid){
      setSelectedMap(data)
    } else {
      alertService.warn(fillInAllTheFields);
    }
    buttonSubmitRef.current.click();

  };

  const previousAction = () => {
    const data = getValues();
    setSelectedMap(data)
    if (selectedForm === 1) {
      history.push(props.previous);
      return;
    } else if (selectedForm === 2) {
      setSelectedForm(1);
    }
    scrollToTop();
  };

  const saveAndContinueLater = async () => {
    const currentData = getValues();
    currentData.temp_trans_id = props.temp_trans_id;
    
    const result = await postData({
      url: `${ROUTES.saveApplication}?app_type=${appType}`,
      body: {
        ...selectedMap,
        temp_trans_id: props.temp_trans_id,
        app_type: appType,
        launchCase: false,
      },
    });

    if (result.statusCode === 200) {
      alertService.success("Application saved successfully", {
        autoClose: true,
        keepAfterRouteChange: true,
      });

      history.replace("/application-types");
    } else {
      alertService.error("Error occured while saving application", {
        autoClose: true,
        keepAfterRouteChange: true,
      });
    }
  };

  const saveYourStore = () => {
    const data = getValues();
    data.temp_trans_id = props.temp_trans_id;
    // localStorage.setItem("ftc_complaints", JSON.stringify(data));
  };

  

  const [selectedForm , setSelectedForm] = useState(1);

  return (
    <div className="container-fluid " ref={componentRef}>
      <div className="head-text">
        {props.isPreview
          ? getPreviewHeading(appType, props.isFormPDF)
          : selectedForm === 1
          ? "Application Form"
          : "Declaration of Knowledge - Form"}
      </div>

      <div className="form-container">
        <fieldset disabled={props.isDisabled || props.isPreview}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            // onChange={() => saveYourStore()}
            ref={props.reference}
          >
            {selectedForm === 1 && (
              <>
                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Applicant Information</div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookInput
                      register={register}
                      error={errors?.nfa_registration_number?.message}
                      label="NFA Registration Number"
                      regFieldName="nfa_registration_number"
                      placeholder="NFA Registration Number"
                      isRequired={false}
                      type="text"
                      isDisabled={true}
                    />

                    <Break />
                    <div className="">
                      <label className="individual-field-margin mt-2">
                        Name of Applicant(Individuals):
                      </label>

                      <div className="d-flex-wrap sub-form-container-no-border">
                        <FormHookInput
                          register={register}
                          error={errors?.last_name_individual?.message}
                          label="Last"
                          placeholder="Last"
                          regFieldName="last_name_individual"
                          isRequired={isRequiredIndividual(selectedMap)}
                        />

                        <FormHookInput
                          register={register}
                          error={errors?.first_name_individual?.message}
                          label="First"
                          regFieldName="first_name_individual"
                          placeholder="First"
                          isRequired={isRequiredIndividual(selectedMap)}
                        />

                        <FormHookInput
                          register={register}
                          error={errors?.middle_name_individual?.message}
                          label="Middle"
                          regFieldName="middle_name_individual"
                          placeholder="Middle"
                          isRequired={false}
                        />

                        <FormHookInput
                          register={register}
                          error={errors?.alias_individual?.message}
                          label="Alias"
                          regFieldName="alias_individual"
                          placeholder="Alias"
                          isRequired={false}
                        />
                      </div>
                    </div>

                    <Break />
                    <FormHookInput
                      register={register}
                      error={errors?.company_name?.message}
                      label="Name of Applicant(If Company)"
                      regFieldName="company_name"
                      placeholder="Name of Applicant"
                      type="text"
                      isRequired={isRequiredCompany(selectedMap)}
                      toAddOptionalText={false}
                    />

                    <FormHookSelect
                      register={register}
                      error={errors?.country_of_origin?.message}
                      label="Nationality / Country of Origin"
                      regFieldName="country_of_origin"
                      options={countries}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.national_registration_id?.message}
                      label="Nationality Registration ID"
                      regFieldName="national_registration_id"
                      placeholder="Nationality Registration ID"
                      type="text"
                      // isRequired={isRequiredIndividual(selectedMap)}
                      isRequired={false}
                      toAddOptionalText={false}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.taxation_registration_number?.message}
                      label="Taxation Registration Number"
                      regFieldName="taxation_registration_number"
                      placeholder="Taxation Registration Number"
                      type="text"
                      isRequired={false}
                      toAddOptionalText={false}
                    />

                    <Break />

                    <FormHookSelect
                      register={register}
                      error={errors?.country_address?.message}
                      label="Country"
                      regFieldName="country_address"
                      options={countries}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.cell_phone?.message}
                      label="Cell Phone"
                      regFieldName="cell_phone"
                      placeholder="Cell Phone"
                      type="text"
                      validations={{
                        pattern: {
                          value: telephonePattern,
                          message: telephoneNotValid,
                        },
                      }}
                      onChange={formatPhoneNumber}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.work_home_phone?.message}
                      label="Work / Home Phone"
                      regFieldName="work_home_phone"
                      placeholder="Work / Home Phone"
                      type="text"
                      isRequired={false}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.email?.message}
                      label="Email"
                      regFieldName="email"
                      placeholder="Email"
                      type="text"
                      validations={{
                        pattern: {
                          value: emailPattern,
                          message: emailNotValid,
                        },
                      }}
                      isRequired={false}
                    />

                    <Break />

                    <FormHookInput
                      register={register}
                      error={errors?.contact_name?.message}
                      label="Contact"
                      regFieldName="contact_name"
                      placeholder="Contact"
                      type="text"
                    />

                    <FormHookRadioInput
                      label="Gender / Type of Application:"
                      fieldClassName="d-flex-wrap flex-row"
                      isKeyValue={true}
                      options={[
                        {
                          label: "Male",
                          value: "Male",
                        },
                        {
                          label: "Female",
                          value: "Female",
                        },
                        {
                          label: "Business",
                          value: "Business",
                        },
                      ]}
                      register={register}
                      error={errors?.gender_type_of_business?.message}
                      regFieldName={`gender_type_of_business`}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.date_of_birth_individual?.message}
                      label="Date of Birth (Individual)"
                      regFieldName="date_of_birth_individual"
                      type="date"
                      isRequired={isRequiredIndividual(selectedMap)}
                      maxDate={getMaxDateForAtleastYear(16)}
                    />

                    <Break />
                    <FormHookTextArea
                      register={register}
                      error={errors?.address?.message}
                      label="Address"
                      regFieldName="address"
                      placeholder="Address"
                      cols={72}
                    />

                    {/* common for all types till here  */}

                    <Break />

                    <div className="">
                      <label className="individual-field-margin mt-2">
                        National Picture Identification{" "}
                        <span style={{ fontSize: "12px" }}>
                          ((for first time applicants and if renewing/replacing
                          NFA ID):)
                        </span>
                      </label>

                      <div className="d-flex-wrap sub-form-container-no-border">
                        <FormHookSelect
                          register={register}
                          error={
                            errors?.type_of_picture_identification?.message
                          }
                          label="Type"
                          regFieldName="type_of_picture_identification"
                          options={individualYesDocList}
                          isDisabled={false}
                          isRequired={
                            wrapMessageforRequired(isRequiredIndividual(selectedMap) && selectedMap.type_of_permit === INDUSTRIAL_VESSEL_CREW_MEMBER)
                          }
                        />

                        <FormHookInput
                          register={register}
                          error={errors?.picture_identification_number?.message}
                          label="No."
                          regFieldName="picture_identification_number"
                          placeholder="No."
                          isDisabled={false}
                          isRequired={
                            wrapMessageforRequired(isRequiredIndividual(selectedMap) && selectedMap.type_of_permit === INDUSTRIAL_VESSEL_CREW_MEMBER)
                          }
                        />

                        <FormHookInput
                          register={register}
                          error={errors?.picture_expiry_date?.message}
                          label="Expiry Date"
                          regFieldName="picture_expiry_date"
                          type="date"
                          isDisabled={false}
                          isRequired={
                            wrapMessageforRequired(isRequiredIndividual(selectedMap) && selectedMap.type_of_permit === INDUSTRIAL_VESSEL_CREW_MEMBER)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {selectedMap.type_of_permit === TOURNAMENT_HOSTING && (
                  <>
                    <div className="shadow p-3 mb-3 bg-white rounded">
                      <div className="d-flex-wrap flex-column justify-content-start">
                        <TournamentInformation
                          formState={formState}
                          register={register}
                          control={control}
                          onSubmit={onSubmit}
                        />
                      </div>
                    </div>

                    <div className="shadow p-3 mb-3 bg-white rounded">
                      <div className="d-flex-wrap flex-column justify-content-start">
                        <TournamentDirectorInformation
                          showHeading={false}
                          formState={formState}
                          register={register}
                          control={control}
                          onSubmit={onSubmit}
                        />
                      </div>
                    </div>

                    <div className="shadow p-3 mb-3 bg-white rounded">
                      <div className="d-flex-wrap flex-column justify-content-start">
                        <LocationDetails
                          showHeading={false}
                          formState={formState}
                          register={register}
                          control={control}
                          onSubmit={onSubmit}
                          typeOfSpecies={
                            typeOfSpecies[selectedMap.type_of_permit]
                          }
                          zoneBeachAreas={fishingAreaPermitLocations}
                        />
                      </div>
                    </div>
                  </>
                )}

                {(selectedMap.type_of_permit ===
                  INDUSTRIAL_VESSEL_CREW_MEMBER ||
                  selectedMap.type_of_permit === TEMPORARY_PERMIT ||
                  selectedMap.type_of_permit === MORANT_PEDRO ||
                  selectedMap.type_of_permit === TOURNAMENT_HOSTING) && (
                  <div className="shadow p-3 mb-3 bg-white rounded">
                    <div className="sub-form-header">Transaction Details</div>
                    <HorizontalLine></HorizontalLine>

                    <div className="d-flex-wrap flex-column justify-content-start">
                      <FormHookCheckbox
                        label="Transaction(s) Required"
                        options={
                          typeOfTransactions[selectedMap.type_of_permit] ?? []
                        }
                        register={register}
                        error={errors?.type_of_transaction?.message}
                        regFieldName={`type_of_transaction`}
                        isDisabled={true}
                      />

                      {/* {selectedMap.type_of_transaction === RENEWAL && (
                        <FormHookCheckbox
                          label="Renewal Type"
                          options={RenewalTypeOptionsList}
                          register={register}
                          error={errors?.renewal_type?.message}
                          regFieldName={`renewal_type`}
                          isDisabled={true}
                        />
                      )} */}

                      {/* Elena asked to remove from all permit */}
                      {/* <FormHookCheckbox
                        label="Licence Type"
                        options={typeOfLicence ?? []}
                        register={register}
                        error={errors?.type_of_licence?.message}
                        regFieldName={`type_of_licence`}
                        isRequired={false}
                      /> */}

                      {/* doubt in applicant role for fisheries in the selection process it is applicant roles as a crew member here it is 
                      applicant role in fisheries */}
                      {/* <FormHookCheckbox
                        label="Applicant's Role in Fisheries"
                        options={applicantRoleInFisheries ?? []}
                        register={register}
                        error={errors?.applicant_role_in_fisheries?.message}
                        regFieldName={`applicant_role_in_fisheries`}
                      /> */}

                      {selectedMap.type_of_permit === MORANT_PEDRO && (
                        <FormHookCheckbox
                          label="Applicant's Role as Crew Member"
                          options={
                            applicantRoleAsCrew[selectedMap.type_of_permit] ??
                            []
                          }
                          register={register}
                          error={errors?.applicant_role_as_crew?.message}
                          regFieldName={`applicant_role_as_crew`}
                        />
                      )}

                      {/* doubt in type of species
                       */}

                      {selectedMap.type_of_permit !== TOURNAMENT_HOSTING && (
                        <FormHookCheckbox
                          label="Type of Species - Fishery to be Licensed for"
                          options={
                            typeOfSpecies[selectedMap.type_of_permit] ?? []
                          }
                          register={register}
                          error={errors?.type_of_species?.message}
                          regFieldName={`type_of_species`}
                        />
                      )}

                      {/* doubt in gear type only in 2 of the types */}

                      {(selectedMap.type_of_permit ===
                        INDUSTRIAL_VESSEL_CREW_MEMBER ||
                        selectedMap.type_of_permit === MORANT_PEDRO ||
                        selectedMap.type_of_permit === TEMPORARY_PERMIT) && (
                        <FormHookCheckbox
                          label="Gear Type to be Licenced"
                          options={
                            gearTypeToBeLicenced[selectedMap.type_of_permit] ??
                            []
                          }
                          register={register}
                          error={errors?.gear_type_to_be_licenced?.message}
                          regFieldName={`gear_type_to_be_licenced`}
                        />
                      )}

                      {(selectedMap.type_of_permit ===
                        INDUSTRIAL_VESSEL_CREW_MEMBER ||
                        selectedMap.type_of_permit === TEMPORARY_PERMIT) && (
                        <>
                          <FormHookCheckbox
                            label="Port Or Inland"
                            options={baseLocations}
                            register={register}
                            error={errors?.port_or_inland?.message}
                            regFieldName={`port_or_inland`}
                            isDisabled={true}
                            isMulti={false}
                          />

                          {selectedMap.port_or_inland ===
                            BASE_LOCATION_PORT && (
                            <div>
                              <FormHookCheckbox
                                label="Region/Parish"
                                options={regionParishList}
                                register={register}
                                error={errors?.region?.message}
                                regFieldName={`region`}
                                isDisabled={true}
                                isMulti={false}
                              />

                              <FormHookCheckbox
                                label="Port"
                                options={regionParishPort?.[selectedMap.region]}
                                register={register}
                                error={errors?.port?.message}
                                regFieldName={`port`}
                                isDisabled={true}
                                isMulti={false}
                              />

                              <FormHookCheckbox
                                label="Fishing Area"
                                options={fishingAreaLocations ?? []}
                                register={register}
                                error={errors?.fishing_area?.message}
                                regFieldName={`fishing_area`}
                                isDisabled={true}
                              />
                            </div>
                          )}

                          <Break />

                          {selectedMap.port_or_inland ===
                            BASE_LOCATION_INLAND && (
                            <div>
                              <FormHookCheckbox
                                label="Region/Parish"
                                options={regionListForInland}
                                register={register}
                                error={errors?.region?.message}
                                regFieldName={`region`}
                                isDisabled={true}
                                isMulti={false}
                              />

                              <Break />

                              <FormHookCheckbox
                                label="Inland"
                                options={InlandList}
                                register={register}
                                error={errors?.inland?.message}
                                regFieldName={`inland`}
                                isDisabled={true}
                                isMulti={false}
                              />
                            </div>
                          )}
                        </>
                      )}

                      {selectedMap.type_of_permit === MORANT_PEDRO && (
                        <div>
                          <FormHookCheckbox
                            label="Permit Required for "
                            options={
                              permitRequiredFor[selectedMap.type_of_permit] ??
                              []
                            }
                            register={register}
                            error={errors?.permit_required_for?.message}
                            regFieldName={`permit_required_for`}
                            isDisabled={true}
                          />

                          <CustomTextAreaContainer
                            heading={"Purpose Additional Details"}
                            prop_key={"purpose_additional_details"}
                            placeholder={"Purpose Additional Details"}
                            selectedMap={selectedMap}
                            setSelectedMap={setSelectedMap}
                            isDisabled={true}
                            fieldClassName=""
                            labelClassName="normal-label"
                          ></CustomTextAreaContainer>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <SimpleDeclaration register={register} errors={errors} />

                {/* <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Declaration</div>
                  <HorizontalLine></HorizontalLine>

                  <div>
                    I / We declare that the information given is true and
                    correct to the best of my / our knowledge and belief.
                  </div>

                  <div className="error-message">
                    {!watch("applicant_signature") &&
                      !watch("signature_draw_path") && (
                        <span>Atleast one field is required from below</span>
                      )}
                  </div>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookFileUploadHandler
                      error={errors?.applicant_signature?.message}
                      register={register}
                      watch={watch}
                      label="Upload Applicant's Signature"
                      accept=".jpg, .jpeg, .png"
                      regFieldName="applicant_signature"
                      fieldClassName="field-block-control"
                      isPreviewable={true}
                      isRequired={false}
                      removeFile={(id) => setValue(id, null)}
                      setValue={setValue}
                    ></FormHookFileUploadHandler>

                    <SignaturePad
                      isDisabled={props.isDisabled || props.isPreview}
                      currentValue={watch("signature_draw_path")}
                      setSignatureValue={(id, base64) => setValue(id, base64)}
                      id="signature_draw_path"
                      label="Or Draw Your Signature Below"
                    />
                  </div>
                </div> */}
              </>
            )}

            {(selectedForm === 2 || props.isPreview) && (
              <GeneralDeclarations
                register={register}
                errors={errors}
                watch={watch}
                isDisabled={props.isDisabled || props.isPreview}
                setValue={setValue}
              />
            )}

            <div className="d-flex flex-row-reverse">
              <div className="ml-2">
                <button
                  hidden={true}
                  ref={buttonSubmitRef}
                  className="btn btn-primary"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </fieldset>
        <div hidden={props.isDisabled || props.isPreview}>
          <SimpleNavigator
            next
            middle
            middleValue={saveAndContinueLaterText}
            middleAction={saveAndContinueLater}
            nextAction={nextAction}
            previousAction={previousAction}
            previous
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    temp_trans_id: state.currentTransaction.temp_trans_id,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationForPermit);
