// import { default as ReactSelect } from "react-select";
// import { Option } from "./Option";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  fishingArea,
  getSVGImage,
  getRectBoxStylingWithMultiTypeValues,
  InternalNavigator,
  getAnimationStyle,
  onChangeMultiSelect,
  specieal_species_allow,
  SPINY_LOBSTER,
  CONCH,
  SEA_CUCUMBER,
  RenewalTypeOptions,
  baseLocations,
  BASE_LOCATION_INLAND,
  InlandList,
  BASE_LOCATION_PORT,
  regionParishList,
  regionParishPort,
  regionListForInland,
  regionInland,
} from "../../components/DataSource";
import {
  CustomInputContainer,
  CustomTextAreaContainer,
  onChangeInput,
} from "../../components/CustomInputContainer";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { FLISContext } from "../../context/FLISContext";
import { useForm } from "react-hook-form";
import BoxChoiceContainer from "../../components/BoxChoiceContainer";

import {
  typeOfPermit,
  INDUSTRIAL_VESSEL_CREW_MEMBER,
  MORANT_PEDRO,
  TEMPORARY_PERMIT,
  TOURNAMENT_HOSTING,
  typeOfTransactions,
  domicileType,
  applicantRoleAsCrew,
  typeOfSpecies,
  gearTypeToBeLicenced,
  purposeOfPermit,
  permitRequiredFor,
  fishingAreaPermitLocations,
  permitTypesDateAllowed,
} from "./PermitDataSource";
import { TournamentInformation } from "./TournamentInformation";
import { TournamentDirectorInformation } from "./TournamentDirectorInfo";
import { LocationDetails } from "./PermitLocationDetails";
import { isDateInRange, shouldAskForNFARegNumber, shouldAskPreviousAppNumber, validateKeys } from "../../utils/utils";
import { alertService } from "../../_services";
import {
  inputPreviousNumber,
  inputSelection,
  selectionNotFound,
} from "../../utils/messages";
import NFARegNumberSelection from "../../components/NFARegNumberQuestion";
import { VesselNamesInfo } from "./VesselNamesInfo";
import { ReactSelect } from "../../components/FormHook/ReactFormHookFields";
import { postData } from "../../services/service-call";
import { ROUTES } from "../../config";
import { icmPropMapping } from "../../components/IcmPropMappingtoUI";
import { set } from "lodash";

export default function PermitTypeSelection(props) {
  const history = useHistory();

  const { selectedMap, setSelectedMap, currentEntity } = useContext(FLISContext);

  const [selectionCategory, setSelectionCategory] = useState(
    selectedMap.selectionCategory ?? 1
  );

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();

  const onMapLoad = (map) => {
    setMapRef(map);
    const bounds = new window.google.maps.LatLngBounds();
    fishingArea?.forEach(({ lat, lng }) => {
      if (lat !== 0) {
        bounds.extend({ lat, lng });
      }
    });
    map.fitBounds(bounds);
  };

  const handleMarkerClick = (id, lat, lng, address) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, address });
    setIsOpen(true);
  };

  const tdiForm = useForm({
    defaultValues: {
      tournament_director_information:
        selectedMap.tournament_director_information ?? [{ id: Math.random() }],
    },
    mode: "all",
  });

  const tiForm = useForm({
    defaultValues: {
      tournament_information: selectedMap.tournament_information,
    },
    mode: "all",
  });

  const vesselNamesForm = useForm({
    defaultValues: {
      vessel_names: selectedMap.vessel_names || [{ id: Math.random() }],
    },
    mode: "all",
  });

  const locationForm = useForm({
    defaultValues: {
      permit_location_species_details:
        selectedMap.permit_location_species_details ?? [{ id: Math.random() }],
    },
    mode: "all",
  });

  const {
    handleSubmit: handleTDISubmit,
    control: tdiFormControl,
    trigger: triggerValidateTDI,
    getValues: geTDIValues,
    register: registerTDI,
    formState: tdiFormState,
  } = tdiForm;

  const {
    handleSubmit: handleTISubmit,
    control: tiFormControl,
    trigger: triggerValidateTI,
    getValues: getTIValues,
    register: registerTI,
    formState: tiFormState,
  } = tiForm;

  const {
    handleSubmit: handleLocationSubmit,
    control: locationFormControl,
    trigger: triggerValidateLocation,
    getValues: getLocationValues,
    register: registerLocation,
    formState: locationFormState,
  } = locationForm;

  const {
    handleSubmit: handleVesselNameSubmit,
    control: vesselNameFormControl,
    trigger: triggerValidateVesselName,
    getValues: getVesselNamesValues,
    register: registerVesselName,
    formState: vesselNameFormState,
  } = vesselNamesForm;

  const onSubmit = (data) => {
    console.log(data);
  };

  const validateTDIForm = async () => {
    try {
      const isFormValid = await triggerValidateTDI();
      const data = await geTDIValues();
      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map,
            tournament_director_information:
              data.tournament_director_information,
          };
        });

        handleTDISubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  const validateTIForm = async () => {
    try {
      const isFormValid = await triggerValidateTI();
      const data = await getTIValues();
      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map,
            tournament_information: data.tournament_information,
          };
        });
        handleTISubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  const validateVesselNameForm = async () => {
    try {
      const isFormValid = await triggerValidateVesselName();
      const data = await getVesselNamesValues();
      if (isFormValid && data?.vessel_names?.length) {
        setSelectedMap((map) => {
          return {
            ...map,
            vessel_names: data.vessel_names,
          };
        });
        handleVesselNameSubmit(onSubmit)();
      } else if (!data?.vessel_names?.length) {
        alertService.warn("Atleast one Vessel Name is required");
        return false;
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  const validateLocationForm = async () => {
    try {
      const isFormValid = await triggerValidateLocation();
      const data = await getLocationValues();
      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map,
            permit_location_species_details:
              data.permit_location_species_details,
          };
        });

        handleLocationSubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  return (
    <div
      className="d-flex flex-column"
      style={{ width: "100%", padding: "10px 10%" }}
    >
      {selectionCategory === 1 && (
        <>
          <BoxChoiceContainer
            listingHeading={"Select Type of Permit"}
            listingOptions={typeOfPermit}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"type_of_permit"}
            onClick={(trans) => {
              if (
                trans !== INDUSTRIAL_VESSEL_CREW_MEMBER ||
                selectedMap.nationality === "Local"
              ) {
                setSelectionCategory(selectionCategory + 1);
              }
            }}
            listingStyle="justify-content-center"
            listingDisabled={[
              false,
              !isDateInRange(permitTypesDateAllowed[MORANT_PEDRO].start_date, permitTypesDateAllowed[MORANT_PEDRO].end_date), // Morant and Pedro disabled 
              false,
              false
            ]}
            listingDescriptions={[
              permitTypesDateAllowed[INDUSTRIAL_VESSEL_CREW_MEMBER]?.description,
              permitTypesDateAllowed[MORANT_PEDRO]?.description,
              permitTypesDateAllowed[TEMPORARY_PERMIT]?.description,
              permitTypesDateAllowed[TOURNAMENT_HOSTING]?.description,
            ]}
          ></BoxChoiceContainer>

          {selectedMap.type_of_permit &&
            selectedMap.type_of_permit === INDUSTRIAL_VESSEL_CREW_MEMBER &&
            selectedMap.nationality === "Foreign" && (
              <CustomInputContainer
                heading={"Enter the Work Permit Number"}
                prop_key={"work_permit_number"}
                placeholder={"Enter the Work Permit number"}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
              ></CustomInputContainer>
            )}

          <div className="d-flex flex-column">
            <InternalNavigator
              prevValue={"Previous"}
              previousAction={() => {
                history.push("/");
                // setSelectionCategory(selectionCategory - 1);
              }}
              nextValue={"Next"}
              nextAction={() => {
                if (!validateKeys(selectedMap, ["type_of_permit"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }

                if (
                  selectedMap.type_of_permit ===
                    INDUSTRIAL_VESSEL_CREW_MEMBER &&
                  selectedMap.nationality === "Foreign"
                ) {
                  if (!validateKeys(selectedMap, ["work_permit_number"])) {
                    alertService.warn(
                      inputPreviousNumber("Work Permit Number"),
                      { autoClose: true }
                    );
                    return;
                  }
                }
                setSelectionCategory(selectionCategory + 1);
              }}
            />
          </div>
        </>
      )}

      {selectedMap.type_of_permit === INDUSTRIAL_VESSEL_CREW_MEMBER && (
        <>
          {selectionCategory === 2 && (
            <div>
              <BoxChoiceContainer
                listingHeading={"Select Type of Transaction"}
                listingOptions={typeOfTransactions[selectedMap.type_of_permit]}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
                selectionCategory={selectionCategory}
                prop_key={"type_of_transaction"}
                onClick={(value, i) => {
                  if (value === "New Permit")
                    setSelectionCategory(selectionCategory + 1);
                }}
                listingStyle="justify-content-center"
                listingDescriptions={["Permit + ID Card"]}
              ></BoxChoiceContainer>

              {/* {
                <div className="d-flex flex-column">
                  {["Renewal"].indexOf(selectedMap.type_of_transaction) >=
                    0 && (
                    <>
                      <div className="rectangle-box-small-heading">
                        Select Type of Renewal
                      </div>

                      <div className="d-flex flex-row justify-content-center">
                        <select
                          name="renewal_type"
                          onChange={(e) => onChangeInput(e, setSelectedMap)}
                          value={selectedMap.renewal_type}
                          className="col-sm-5 form-control form-control-sm"
                        >
                          <RenewalTypeOptions />
                        </select>
                      </div>
                    </>
                  )}
                </div>
              } */}

              {shouldAskPreviousAppNumber(selectedMap) && (
                <CustomInputContainer
                  heading={"Enter the Permit Number"}
                  prop_key={"previous_permit_number"}
                  placeholder={"Enter Permit number"}
                  selectedMap={selectedMap}
                  setSelectedMap={setSelectedMap}
                ></CustomInputContainer>
              )}

              {
                <div className="d-flex flex-column">
                  {shouldAskForNFARegNumber(selectedMap) && (
                    <>
                      <div className="rectangle-box-small-heading">
                        Enter NFA Registration Number
                      </div>

                      <div className="d-flex flex-row justify-content-center">
                        <input
                          type="text"
                          name="nfa_registration_number"
                          onChange={(e) => onChangeInput(e, setSelectedMap)}
                          value={selectedMap.nfa_registration_number}
                          className="col-sm-5 form-control form-control-sm"
                          placeholder={"Enter NFA Registration Number"}
                        />
                      </div>
                    </>
                  )}
                </div>
              }

              <div className="d-flex flex-column">
                <InternalNavigator
                  prevValue={"Previous"}
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                  nextValue={"Next"}
                  nextAction={async () => {
                    if (!validateKeys(selectedMap, ["type_of_transaction"])) {
                      alertService.warn(selectionNotFound);
                      return;
                    }

                    if (shouldAskPreviousAppNumber(selectedMap)) {
                      if (
                        !validateKeys(selectedMap, ["previous_permit_number"])
                      ) {
                        alertService.warn(inputPreviousNumber("Permit Number"));
                        return;
                      }
                    }

                    if (
                      ["Renewal"].indexOf(selectedMap.type_of_transaction) !==
                        -1 &&
                      !validateKeys(selectedMap, ["renewal_type"])
                      // after permits renewal have no type
                      && false
                    ) {
                      alertService.warn(inputSelection("Renewal Type"));
                      return;
                    } else if (
                      shouldAskForNFARegNumber(selectedMap) &&
                      !validateKeys(selectedMap, ["nfa_registration_number"])
                    ) {
                      alertService.warn(
                        inputPreviousNumber("NFA Registration Number")
                      );
                      return;
                    } else if (
                      ["Renewal"].indexOf(selectedMap.type_of_transaction) !==
                        -1 &&
                      validateKeys(selectedMap, ["renewal_type"]) &&
                      process.env.REACT_APP_DO_RENEWAL_API == 1
                    ) {
                      // do api call for getting the renewal data here
                      const result = await postData({
                        url: `${ROUTES.getPermitRenewalData}`,
                        body: {
                          permitNumber: selectedMap.previous_permit_number,
                          nfaRegisterNumber:
                            selectedMap.nfa_registration_number,
                          entityNumber: currentEntity.entity_number,
                          // "permitNumber": "123456",
                          // "nfaRegisterNumber": "sfafasf",
                          // "entityNumber": "IN-0287113",
                        },
                      });

                      let map = {};
                      Object.keys(result?.data?.result ?? []).forEach((key) => {
                        // map[icmPropMapping['APPLICATION_FOR_LICENCE'][key] || ''] = result?.data?.result[key];
                        if (icmPropMapping["APPLICATION_FOR_PERMIT"][key])
                          set(
                            map,
                            icmPropMapping["APPLICATION_FOR_PERMIT"][key],
                            result?.data?.result[key]
                          );
                      });
                      console.log(map);
                      setSelectedMap((data) => {
                        return {
                          ...data,
                          ...map,
                        };
                      });
                    }

                    setSelectionCategory(selectionCategory + 1);
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 3 && (
            <div>
              <VesselNamesInfo
                formState={vesselNameFormState}
                register={registerVesselName}
                control={vesselNameFormControl}
                onSubmit={onSubmit}
              />

              <div className="d-flex flex-column">
                <InternalNavigator
                  nextValue={"Next"}
                  nextAction={async () => {
                    if (await validateVesselNameForm()) {
                      setSelectionCategory(selectionCategory + 1);
                    } else {
                    }
                  }}
                  prevValue="Go Back"
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                />
              </div>
            </div>
          )}

          {/* Elena asked to remove */}
           {selectionCategory === 4 && (
            <div>
              <BoxChoiceContainer
                listingHeading={"Select Applicant's Role as Crew Member"}
                listingOptions={applicantRoleAsCrew[selectedMap.type_of_permit]}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
                selectionCategory={selectionCategory}
                prop_key={"applicant_role_as_crew"}
                listingStyle="justify-content-center"
                onClick={() => {
                  setSelectionCategory(selectionCategory + 1);
                }}
              ></BoxChoiceContainer>

              <div className="d-flex flex-column">
                <InternalNavigator
                  prevValue={"Previous"}
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                  nextValue={"Next"}
                  nextAction={() => {
                    if (
                      !validateKeys(selectedMap, ["applicant_role_as_crew"])
                    ) {
                      alertService.warn(selectionNotFound);
                      return;
                    }

                    setSelectionCategory(selectionCategory + 1);
                  }}
                />
              </div>
            </div>
          )} 

          {selectionCategory === 5 && (
            <div>
              <BoxChoiceContainer
                listingHeading={"Select Type of Species"}
                listingOptions={typeOfSpecies[selectedMap.type_of_permit]}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
                selectionCategory={selectionCategory}
                prop_key={"type_of_species"}
                listingStyle="justify-content-center"
                isMultiSelect={true}
                listingDescriptions={[
                  specieal_species_allow[SPINY_LOBSTER].description,
                  specieal_species_allow[CONCH].description,
                  specieal_species_allow[CONCH].description,
                  specieal_species_allow[SEA_CUCUMBER].description,
                ]}
                listingDisabled={[
                  !isDateInRange(
                    specieal_species_allow[SPINY_LOBSTER].start_date,
                    specieal_species_allow[SPINY_LOBSTER].end_date
                  ),
                  !isDateInRange(
                    specieal_species_allow[CONCH].start_date,
                    specieal_species_allow[CONCH].end_date
                  ),
                  !isDateInRange(
                    specieal_species_allow[CONCH].start_date,
                    specieal_species_allow[CONCH].end_date
                  ),
                  !isDateInRange(
                    specieal_species_allow[SEA_CUCUMBER].start_date,
                    specieal_species_allow[SEA_CUCUMBER].end_date
                  ),
                ]}
              ></BoxChoiceContainer>

              <div className="d-flex flex-column">
                <InternalNavigator
                  prevValue={"Previous"}
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                  nextValue={"Next"}
                  nextAction={() => {
                    if (!validateKeys(selectedMap, ["type_of_species"])) {
                      alertService.warn(selectionNotFound);
                      return;
                    }

                    setSelectionCategory(selectionCategory + 1);
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 6 && (
            <div>
              <BoxChoiceContainer
                listingHeading={"Select Gear Type to be Licenced"}
                listingOptions={
                  gearTypeToBeLicenced[selectedMap.type_of_permit]
                }
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
                selectionCategory={selectionCategory}
                prop_key={"gear_type_to_be_licenced"}
                listingStyle="justify-content-center"
                isMultiSelect={true}
                boxStyle="sub-cat-container"
                textStyle="rectangle-box-small-text"
              ></BoxChoiceContainer>

              {selectedMap?.gear_type_to_be_licenced &&
                selectedMap.gear_type_to_be_licenced?.indexOf("Other") !==
                  -1 && (
                  <CustomInputContainer
                    heading={"Other types of Gear"}
                    prop_key={"other_types_of_gear"}
                    placeholder={"Enter Other types of Gear"}
                    selectedMap={selectedMap}
                    setSelectedMap={setSelectedMap}
                  ></CustomInputContainer>
                )}

              <div className="d-flex flex-column">
                <InternalNavigator
                  prevValue={"Previous"}
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                  nextValue={"Next"}
                  nextAction={() => {
                    if (
                      !validateKeys(selectedMap, ["gear_type_to_be_licenced"])
                    ) {
                      alertService.warn(selectionNotFound);
                      return;
                    }

                    if (
                      selectedMap.gear_type_to_be_licenced?.indexOf("Other") >=
                      0
                    ) {
                      if (!validateKeys(selectedMap, ["other_types_of_gear"])) {
                        alertService.warn(selectionNotFound, {
                          autoClose: true,
                        });
                        return;
                      }
                    }

                    setSelectionCategory(selectionCategory + 1);
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 7 && (
            <div style={getAnimationStyle(selectionCategory === 7)}>
              <BoxChoiceContainer
                listingHeading={"Select Port or Inland"}
                listingOptions={baseLocations}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
                selectionCategory={selectionCategory}
                prop_key={"port_or_inland"}
                onClick={() => setSelectionCategory(selectionCategory + 1)}
                listingStyle="justify-content-center"
                listingDescriptions={[null, "(Rivers and Ponds)"]}
              />

              <div className="d-flex flex-column">
                <InternalNavigator
                  nextValue={"Next"}
                  nextAction={() => {
                    if (!validateKeys(selectedMap, ["port_or_inland"])) {
                      alertService.warn(selectionNotFound);
                      return;
                    }

                    setSelectionCategory(selectionCategory + 1);
                  }}
                  prevValue="Previous"
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 8 &&
            selectedMap.port_or_inland === BASE_LOCATION_INLAND && (
              <div style={getAnimationStyle(selectionCategory === 8)}>
                {/* <BoxChoiceContainer
                  listingHeading={"Select Inland"}
                  listingOptions={InlandList}
                  selectedMap={selectedMap}
                  setSelectedMap={setSelectedMap}
                  selectionCategory={selectionCategory}
                  prop_key={"inland"}
                  listingStyle="justify-content-center"
                  boxStyle="sub-cat-container"
                  textStyle="rectangle-box-small-text"
                />

                {selectedMap.inland === "Other" && (
                  <CustomInputContainer
                    heading={"Other"}
                    prop_key={"other_inland"}
                    placeholder={"Other"}
                    selectedMap={selectedMap}
                    setSelectedMap={setSelectedMap}
                  ></CustomInputContainer>
                )} */}

                <div className="d-flex-wrap flex-row justify-content-center">
                  <ReactSelect
                    options={regionListForInland}
                    label="Select Region/Parish"
                    value={selectedMap.region}
                    onChange={(e) => {
                      setSelectedMap((map) => {
                        return {
                          ...map,
                          region: e.target.value,
                          inland: "",
                        };
                      });
                    }}
                  />

                  {selectedMap.region && (
                    <ReactSelect
                      options={regionInland?.[selectedMap.region]}
                      value={selectedMap.inland}
                      label="Select Inland"
                      onChange={(e) => {
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            inland: e.target.value,
                          };
                        });
                      }}
                    />
                  )}
                </div>

                <div className="d-flex flex-column">
                  <InternalNavigator
                    nextValue={"Next"}
                    nextAction={() => {
                      if (!validateKeys(selectedMap, ["inland"])) {
                        alertService.warn(selectionNotFound, {
                          autoClose: true,
                        });
                        return;
                      }

                      if (
                        selectedMap.inland === "Other" &&
                        !validateKeys(selectedMap, ["other_inland"])
                      ) {
                        alertService.warn(inputPreviousNumber("Other Inland"), {
                          autoClose: true,
                        });
                        return;
                      }

                      props?.next();
                    }}
                    prevValue="Previous"
                    previousAction={() => {
                      setSelectionCategory(selectionCategory - 1);
                    }}
                  />
                </div>
              </div>
            )}

          {selectionCategory === 8 &&
            selectedMap.port_or_inland === BASE_LOCATION_PORT && (
              <div style={getAnimationStyle(selectionCategory === 8)}>
                <div className="d-flex-wrap flex-row justify-content-center">
                  <ReactSelect
                    options={regionParishList}
                    label="Select Region/Parish"
                    value={selectedMap.region}
                    onChange={(e) => {
                      setSelectedMap((map) => {
                        return {
                          ...map,
                          region: e.target.value,
                          port: "",
                        };
                      });
                    }}
                  />

                  {selectedMap.region && (
                    <ReactSelect
                      options={regionParishPort?.[selectedMap.region]}
                      value={selectedMap.port}
                      label="Select Port"
                      onChange={(e) => {
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            port: e.target.value,
                          };
                        });
                      }}
                    />
                  )}
                </div>

                <div className="d-flex flex-column">
                  <InternalNavigator
                    nextValue={"Next"}
                    nextAction={() => {
                      if (!validateKeys(selectedMap, ["region", "port"])) {
                        alertService.warn(selectionNotFound, {
                          autoClose: true,
                        });
                        return;
                      }
                      setSelectionCategory(selectionCategory + 1);
                    }}
                    prevValue="Previous"
                    previousAction={() => {
                      setSelectionCategory(selectionCategory - 1);
                    }}
                  />
                </div>
              </div>
            )}

          {selectionCategory === 9 &&
            selectedMap.port_or_inland === BASE_LOCATION_PORT && (
              <div>
                <div style={getAnimationStyle(selectionCategory === 9)}>
                  <div className="d-flex flex-row justify-content-between">
                    <div>
                      {isLoaded && (
                        <div className="flex-grow-1 GoogleApp-small mt-4 ">
                          <GoogleMap
                            mapContainerClassName="map-container"
                            onLoad={onMapLoad}
                            onClick={() => setIsOpen(false)}
                          >
                            {fishingArea.map(
                              ({ location, lat, lng, id }, ind) => (
                                <Marker
                                  key={id}
                                  position={{ lat, lng }}
                                  onClick={() => {
                                    handleMarkerClick(id, lat, lng, location);
                                    onChangeMultiSelect(
                                      {
                                        target: {
                                          value: location,
                                          name: "fishing_area",
                                        },
                                      },
                                      selectedMap,
                                      setSelectedMap
                                    );
                                  }}
                                >
                                  {isOpen && infoWindowData?.id === id && (
                                    <InfoWindow
                                      onCloseClick={() => {
                                        setIsOpen(false);
                                      }}
                                    >
                                      <span>{infoWindowData.address}</span>
                                    </InfoWindow>
                                  )}
                                </Marker>
                              )
                            )}
                          </GoogleMap>
                        </div>
                      )}
                    </div>

                    <div className="ml-4 pl-2 d-flex flex-column flex-grow-1">
                      <div className="rectangle-box-small-heading">
                        Select Fishing Area(s)
                      </div>

                      <div
                        className="d-flex-wrap flex-row justify-content-start"
                        style={{ margin: "10px 30px 26px 0px" }}
                      >
                        {fishingArea?.map((fishing_area, i) => {
                          return (
                            <div
                              key={i}
                              onClick={() => {
                                onChangeMultiSelect(
                                  {
                                    target: {
                                      value: fishing_area.location,
                                      name: "fishing_area",
                                    },
                                  },
                                  selectedMap,
                                  setSelectedMap
                                );
                              }}
                              className="p-2 location-cat-container"
                              style={{
                                ...getRectBoxStylingWithMultiTypeValues(
                                  selectedMap,
                                  "fishing_area",
                                  fishing_area.location
                                ),
                                maxWidth: "130px",
                              }}
                            >
                              <div style={{ textAlign: "center" }}>
                                {getSVGImage(
                                  32,
                                  32,
                                  selectedMap["fishing_area"] ===
                                    fishing_area.location
                                    ? "black"
                                    : "#cbf1ff"
                                )}

                                <div className="rectangle-box-small-text">
                                  {fishing_area.location}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <div></div>
                    </div>
                  </div>

                  <div className="d-flex flex-column">
                    <InternalNavigator
                      nextValue={"Next"}
                      nextAction={() => {
                        if (!validateKeys(selectedMap, ["fishing_area"])) {
                          alertService.warn(selectionNotFound, {
                            autoClose: true,
                          });
                          return;
                        }

                        setSelectedMap((map) => {
                          return {
                            ...map,
                            selectionCategory,
                          };
                        });

                        // setSelectionCategory(selectionCategory + 1);

                        props?.next();
                      }}
                      prevValue="Go Back"
                      previousAction={() => {
                        setSelectionCategory(selectionCategory - 1);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
        </>
      )}

      {selectedMap.type_of_permit === MORANT_PEDRO && (
        <>
          {selectionCategory === 2 && (
            <div>
              <BoxChoiceContainer
                listingHeading={"Select Type of Transaction"}
                listingOptions={typeOfTransactions[selectedMap.type_of_permit]}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
                selectionCategory={selectionCategory}
                prop_key={"type_of_transaction"}
                listingStyle="justify-content-center"
                onClick={(value, i) => {
                  if (value === "New Permit")
                    setSelectionCategory(selectionCategory + 1);
                }}
                listingDescriptions={["Permit + ID Card"]}
              ></BoxChoiceContainer>

              {/* {
                <div className="d-flex flex-column">
                  {["Renewal"].indexOf(selectedMap.type_of_transaction) >=
                    0 && (
                    <>
                      <div className="rectangle-box-small-heading">
                        Select Type of Renewal
                      </div>

                      <div className="d-flex flex-row justify-content-center">
                        <select
                          name="renewal_type"
                          onChange={(e) => onChangeInput(e, setSelectedMap)}
                          value={selectedMap.renewal_type}
                          className="col-sm-5 form-control form-control-sm"
                        >
                          <RenewalTypeOptions />
                        </select>
                      </div>
                    </>
                  )}
                </div>
              } */}

              {shouldAskPreviousAppNumber(selectedMap) && (
                <CustomInputContainer
                  heading={"Enter the Permit Number"}
                  prop_key={"previous_permit_number"}
                  placeholder={"Enter Permit number"}
                  selectedMap={selectedMap}
                  setSelectedMap={setSelectedMap}
                ></CustomInputContainer>
              )}

              {
                <div className="d-flex flex-column">
                  {shouldAskForNFARegNumber(selectedMap) && (
                    <>
                      <div className="rectangle-box-small-heading">
                        Enter NFA Registration Number
                      </div>

                      <div className="d-flex flex-row justify-content-center">
                        <input
                          type="text"
                          name="nfa_registration_number"
                          onChange={(e) => onChangeInput(e, setSelectedMap)}
                          value={selectedMap.nfa_registration_number}
                          className="col-sm-5 form-control form-control-sm"
                          placeholder={"Enter NFA Registration Number"}
                        />
                      </div>
                    </>
                  )}
                </div>
              }

              <div className="d-flex flex-column">
                <InternalNavigator
                  prevValue={"Previous"}
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                  nextValue={"Next"}
                  nextAction={() => {
                    if (!validateKeys(selectedMap, ["type_of_transaction"])) {
                      alertService.warn(selectionNotFound);
                      return;
                    }

                    if (
                      ["Renewal"].indexOf(selectedMap.type_of_transaction) !==
                        -1 &&
                      !validateKeys(selectedMap, ["renewal_type"])
                      // after permits renewal have no type
                      && false
                    ) {
                      alertService.warn(inputSelection("Renewal Type"));
                      return;
                    } else if (
                      shouldAskForNFARegNumber(selectedMap) &&
                      !validateKeys(selectedMap, ["nfa_registration_number"])
                    ) {
                      alertService.warn(
                        inputPreviousNumber("NFA Registration Number")
                      );
                      return;
                    } 

                    if (shouldAskPreviousAppNumber(selectedMap)) {
                      if (
                        !validateKeys(selectedMap, ["previous_permit_number"])
                      ) {
                        alertService.warn(inputPreviousNumber("Permit Number"));
                        return;
                      }
                    }

                    setSelectionCategory(selectionCategory + 1);
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 3 && (
            <div>
              <BoxChoiceContainer
                listingHeading={"Select Applicant's Role as Crew Member"}
                listingOptions={applicantRoleAsCrew[selectedMap.type_of_permit]}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
                selectionCategory={selectionCategory}
                prop_key={"applicant_role_as_crew"}
                isMultiSelect={true}
                listingStyle="justify-content-around"
              ></BoxChoiceContainer>

              <div className="d-flex flex-column">
                <InternalNavigator
                  prevValue={"Previous"}
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                  nextValue={"Next"}
                  nextAction={() => {
                    if (
                      !validateKeys(selectedMap, ["applicant_role_as_crew"])
                    ) {
                      alertService.warn(selectionNotFound);
                      return;
                    }

                    setSelectionCategory(selectionCategory + 1);
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 4 && (
            <div>
              <BoxChoiceContainer
                listingHeading={"Select Type of Species"}
                listingOptions={typeOfSpecies[selectedMap.type_of_permit]}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
                selectionCategory={selectionCategory}
                prop_key={"type_of_species"}
                listingStyle="justify-content-center"
                isMultiSelect={true}
                listingDescriptions={[
                  specieal_species_allow[SPINY_LOBSTER].description,
                  specieal_species_allow[CONCH].description,
                  specieal_species_allow[CONCH].description,
                  specieal_species_allow[SEA_CUCUMBER].description,
                ]}
                listingDisabled={[
                  !isDateInRange(
                    specieal_species_allow[SPINY_LOBSTER].start_date,
                    specieal_species_allow[SPINY_LOBSTER].end_date
                  ),
                  !isDateInRange(
                    specieal_species_allow[CONCH].start_date,
                    specieal_species_allow[CONCH].end_date
                  ),
                  !isDateInRange(
                    specieal_species_allow[CONCH].start_date,
                    specieal_species_allow[CONCH].end_date
                  ),
                  !isDateInRange(
                    specieal_species_allow[SEA_CUCUMBER].start_date,
                    specieal_species_allow[SEA_CUCUMBER].end_date
                  ),
                ]}
              ></BoxChoiceContainer>

              <div className="d-flex flex-column">
                <InternalNavigator
                  prevValue={"Previous"}
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                  nextValue={"Next"}
                  nextAction={() => {
                    if (!validateKeys(selectedMap, ["type_of_species"])) {
                      alertService.warn(selectionNotFound);
                      return;
                    }
                    setSelectionCategory(selectionCategory + 1);
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 5 && (
            <div>
              <BoxChoiceContainer
                listingHeading={"Select Gear Type to be Licenced"}
                listingOptions={
                  gearTypeToBeLicenced[selectedMap.type_of_permit]
                }
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
                selectionCategory={selectionCategory}
                prop_key={"gear_type_to_be_licenced"}
                listingStyle="justify-content-center"
                isMultiSelect={true}
                boxStyle="sub-cat-container"
                textStyle="rectangle-box-small-text"
              ></BoxChoiceContainer>

              {selectedMap?.gear_type_to_be_licenced &&
                selectedMap.gear_type_to_be_licenced?.indexOf("Other") !==
                  -1 && (
                  <CustomInputContainer
                    heading={"Other types of Gear"}
                    prop_key={"other_types_of_gear"}
                    placeholder={"Enter Other types of Gear"}
                    selectedMap={selectedMap}
                    setSelectedMap={setSelectedMap}
                  ></CustomInputContainer>
                )}

              <div className="d-flex flex-column">
                <InternalNavigator
                  prevValue={"Previous"}
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                  nextValue={"Next"}
                  nextAction={() => {
                    if (
                      !validateKeys(selectedMap, ["gear_type_to_be_licenced"])
                    ) {
                      alertService.warn(selectionNotFound);
                      return;
                    }

                    if (
                      selectedMap.gear_type_to_be_licenced?.indexOf("Other") >=
                      0
                    ) {
                      if (!validateKeys(selectedMap, ["other_types_of_gear"])) {
                        alertService.warn(selectionNotFound, {
                          autoClose: true,
                        });
                        return;
                      }
                    }

                    setSelectionCategory(selectionCategory + 1);
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 6 && (
            <div>
              <BoxChoiceContainer
                listingHeading={"Select Permit Required for"}
                listingOptions={permitRequiredFor[selectedMap.type_of_permit]}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
                selectionCategory={selectionCategory}
                prop_key={"permit_required_for"}
                listingStyle="justify-content-center"
                isMultiSelect={false}
                boxStyle="sub-cat-container"
                textStyle="rectangle-box-small-text"
              ></BoxChoiceContainer>

              <div className="d-flex flex-column">
                <InternalNavigator
                  prevValue={"Previous"}
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                  nextValue={"Next"}
                  nextAction={() => {
                    if (!validateKeys(selectedMap, ["permit_required_for"])) {
                      alertService.warn(selectionNotFound);
                      return;
                    }
                    // props?.next();
                    setSelectionCategory(selectionCategory + 1);
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 7 && (
            <div>
              <CustomTextAreaContainer
                heading={"Purpose Additional Details"}
                prop_key={"purpose_additional_details"}
                placeholder={"Purpose Additional Details"}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
              ></CustomTextAreaContainer>

              <div className="d-flex flex-column">
                <InternalNavigator
                  prevValue={"Previous"}
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                  nextValue={"Next"}
                  nextAction={() => {
                    if (
                      !validateKeys(selectedMap, ["purpose_additional_details"])
                    ) {
                      alertService.warn(selectionNotFound);
                      return;
                    }

                    setSelectionCategory(selectionCategory + 1);
                    setSelectedMap((map) => {
                      return {
                        ...map,
                        selectionCategory,
                      };
                    });

                    // props?.next();
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 8 && (
            <div style={getAnimationStyle(selectionCategory === 8)}>
              <BoxChoiceContainer
                listingHeading={"Select Port or Inland"}
                listingOptions={baseLocations}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
                selectionCategory={selectionCategory}
                prop_key={"port_or_inland"}
                onClick={() => setSelectionCategory(selectionCategory + 1)}
                listingStyle="justify-content-center"
                listingDescriptions={[null, "(Rivers and Ponds)"]}
              />

              <div className="d-flex flex-column">
                <InternalNavigator
                  nextValue={"Next"}
                  nextAction={() => {
                    if (!validateKeys(selectedMap, ["port_or_inland"])) {
                      alertService.warn(selectionNotFound);
                      return;
                    }

                    setSelectionCategory(selectionCategory + 1);
                  }}
                  prevValue="Previous"
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 9 &&
            selectedMap.port_or_inland === BASE_LOCATION_INLAND && (
              <div style={getAnimationStyle(selectionCategory === 9)}>
                {/* <BoxChoiceContainer
                  listingHeading={"Select Inland"}
                  listingOptions={InlandList}
                  selectedMap={selectedMap}
                  setSelectedMap={setSelectedMap}
                  selectionCategory={selectionCategory}
                  prop_key={"inland"}
                  listingStyle="justify-content-center"
                  boxStyle="sub-cat-container"
                  textStyle="rectangle-box-small-text"
                />

                {selectedMap.inland === "Other" && (
                  <CustomInputContainer
                    heading={"Other"}
                    prop_key={"other_inland"}
                    placeholder={"Other"}
                    selectedMap={selectedMap}
                    setSelectedMap={setSelectedMap}
                  ></CustomInputContainer>
                )} */}

                <div className="d-flex-wrap flex-row justify-content-center">
                  <ReactSelect
                    options={regionListForInland}
                    label="Select Region/Parish"
                    value={selectedMap.region}
                    onChange={(e) => {
                      setSelectedMap((map) => {
                        return {
                          ...map,
                          region: e.target.value,
                          inland: "",
                        };
                      });
                    }}
                  />

                  {selectedMap.region && (
                    <ReactSelect
                      options={regionInland?.[selectedMap.region]}
                      value={selectedMap.inland}
                      label="Select Inland"
                      onChange={(e) => {
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            inland: e.target.value,
                          };
                        });
                      }}
                    />
                  )}
                </div>

                <div className="d-flex flex-column">
                  <InternalNavigator
                    nextValue={"Next"}
                    nextAction={() => {
                      if (!validateKeys(selectedMap, ["inland"])) {
                        alertService.warn(selectionNotFound, {
                          autoClose: true,
                        });
                        return;
                      }

                      if (
                        selectedMap.inland === "Other" &&
                        !validateKeys(selectedMap, ["other_inland"])
                      ) {
                        alertService.warn(inputPreviousNumber("Other Inland"), {
                          autoClose: true,
                        });
                        return;
                      }

                      props?.next();
                    }}
                    prevValue="Previous"
                    previousAction={() => {
                      setSelectionCategory(selectionCategory - 1);
                    }}
                  />
                </div>
              </div>
            )}

          {selectionCategory === 9 &&
            selectedMap.port_or_inland === BASE_LOCATION_PORT && (
              <div style={getAnimationStyle(selectionCategory === 9)}>
                <div className="d-flex-wrap flex-row justify-content-center">
                  <ReactSelect
                    options={regionParishList}
                    label="Select Region/Parish"
                    value={selectedMap.region}
                    onChange={(e) => {
                      setSelectedMap((map) => {
                        return {
                          ...map,
                          region: e.target.value,
                          port: "",
                        };
                      });
                    }}
                  />

                  {selectedMap.region && (
                    <ReactSelect
                      options={regionParishPort?.[selectedMap.region]}
                      value={selectedMap.port}
                      label="Select Port"
                      onChange={(e) => {
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            port: e.target.value,
                          };
                        });
                      }}
                    />
                  )}
                </div>

                <div className="d-flex flex-column">
                  <InternalNavigator
                    nextValue={"Next"}
                    nextAction={() => {
                      if (!validateKeys(selectedMap, ["region", "port"])) {
                        alertService.warn(selectionNotFound, {
                          autoClose: true,
                        });
                        return;
                      }

                      props?.next();
                    }}
                    prevValue="Previous"
                    previousAction={() => {
                      setSelectionCategory(selectionCategory - 1);
                    }}
                  />
                </div>
              </div>
            )}
        </>
      )}

      {selectedMap.type_of_permit === TEMPORARY_PERMIT && (
        <>
          {selectionCategory === 2 && (
            <div>
              <BoxChoiceContainer
                listingHeading={"Select Purpose of Permit"}
                listingOptions={purposeOfPermit[selectedMap.type_of_permit]}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
                selectionCategory={selectionCategory}
                prop_key={"purpose_of_permit"}
                listingStyle="justify-content-center"
                onClick={() => {
                  setSelectionCategory(selectionCategory + 1);
                }}
              ></BoxChoiceContainer>

              <div className="d-flex flex-column">
                <InternalNavigator
                  prevValue={"Previous"}
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                  nextValue={"Next"}
                  nextAction={() => {
                    if (!validateKeys(selectedMap, ["purpose_of_permit"])) {
                      alertService.warn(selectionNotFound);
                      return;
                    }

                    setSelectionCategory(selectionCategory + 1);
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 3 && (
            <div>
              <BoxChoiceContainer
                listingHeading={"Select Type of Transaction"}
                listingOptions={typeOfTransactions[selectedMap.type_of_permit]}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
                selectionCategory={selectionCategory}
                prop_key={"type_of_transaction"}
                onClick={(value, i) => {
                  if (value !== "Renewal")
                    setSelectionCategory(selectionCategory + 1);
                }}
                listingStyle="justify-content-center"
                listingDescriptions={["Permit + ID Card"]}
              ></BoxChoiceContainer>

              {/* {
                <div className="d-flex flex-column">
                  {["Renewal"].indexOf(selectedMap.type_of_transaction) >=
                    0 && (
                    <>
                      <div className="rectangle-box-small-heading">
                        Select Type of Renewal
                      </div>

                      <div className="d-flex flex-row justify-content-center">
                        <select
                          name="renewal_type"
                          onChange={(e) => onChangeInput(e, setSelectedMap)}
                          value={selectedMap.renewal_type}
                          className="col-sm-5 form-control form-control-sm"
                        >
                          <RenewalTypeOptions />
                        </select>
                      </div>
                    </>
                  )}
                </div>
              } */}

              {shouldAskPreviousAppNumber(selectedMap) && (
                <CustomInputContainer
                  heading={"Enter the Permit Number"}
                  prop_key={"previous_permit_number"}
                  placeholder={"Enter Permit number"}
                  selectedMap={selectedMap}
                  setSelectedMap={setSelectedMap}
                ></CustomInputContainer>
              )}

              {
                <div className="d-flex flex-column">
                  {shouldAskForNFARegNumber(selectedMap) && (
                    <>
                      <div className="rectangle-box-small-heading">
                        Enter NFA Registration Number
                      </div>

                      <div className="d-flex flex-row justify-content-center">
                        <input
                          type="text"
                          name="nfa_registration_number"
                          onChange={(e) => onChangeInput(e, setSelectedMap)}
                          value={selectedMap.nfa_registration_number}
                          className="col-sm-5 form-control form-control-sm"
                          placeholder={"Enter NFA Registration Number"}
                        />
                      </div>
                    </>
                  )}
                </div>
              }

              <div className="d-flex flex-column">
                <InternalNavigator
                  prevValue={"Previous"}
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                  nextValue={"Next"}
                  nextAction={() => {
                    if (!validateKeys(selectedMap, ["type_of_transaction"])) {
                      alertService.warn(selectionNotFound);
                      return;
                    }

                    if (
                      ["Renewal"].indexOf(selectedMap.type_of_transaction) !==
                        -1 &&
                      !validateKeys(selectedMap, ["renewal_type"])
                      // after permits renewal have no type
                      && false
                    ) {
                      alertService.warn(inputSelection("Renewal Type"));
                      return;
                    } else if (
                      shouldAskForNFARegNumber(selectedMap) &&
                      !validateKeys(selectedMap, ["nfa_registration_number"])
                    ) {
                      alertService.warn(
                        inputPreviousNumber("NFA Registration Number")
                      );
                      return;
                    } 

                    if (shouldAskPreviousAppNumber(selectedMap)) {
                      if (
                        !validateKeys(selectedMap, ["previous_permit_number"])
                      ) {
                        alertService.warn(inputPreviousNumber("Permit Number"));
                        return;
                      }
                    }

                    setSelectionCategory(selectionCategory + 1);
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 4 && (
            <div>
              <BoxChoiceContainer
                listingHeading={"Select Type of Species"}
                listingOptions={typeOfSpecies[selectedMap.type_of_permit]}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
                selectionCategory={selectionCategory}
                prop_key={"type_of_species"}
                listingStyle="justify-content-center"
                isMultiSelect={true}
                boxStyle="sub-cat-container"
                textStyle="rectangle-box-small-text"
              ></BoxChoiceContainer>

              <div className="d-flex flex-column">
                <InternalNavigator
                  prevValue={"Previous"}
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                  nextValue={"Next"}
                  nextAction={() => {
                    if (!validateKeys(selectedMap, ["type_of_species"])) {
                      alertService.warn(selectionNotFound);
                      return;
                    }

                    setSelectionCategory(selectionCategory + 1);
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 5 && (
            <div>
              <BoxChoiceContainer
                listingHeading={"Select Gear Type to be Licenced"}
                listingOptions={
                  gearTypeToBeLicenced[selectedMap.type_of_permit]
                }
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
                selectionCategory={selectionCategory}
                prop_key={"gear_type_to_be_licenced"}
                listingStyle="justify-content-around"
                isMultiSelect={true}
                boxStyle="sub-cat-container"
                textStyle="rectangle-box-small-text"
              ></BoxChoiceContainer>

              {selectedMap?.gear_type_to_be_licenced &&
                selectedMap.gear_type_to_be_licenced?.indexOf("Other") !==
                  -1 && (
                  <CustomInputContainer
                    heading={"Other"}
                    prop_key={"other_types_of_gear"}
                    placeholder={"Enter Other types of Gear"}
                    selectedMap={selectedMap}
                    setSelectedMap={setSelectedMap}
                  ></CustomInputContainer>
                )}

              <div className="d-flex flex-column">
                <InternalNavigator
                  prevValue={"Previous"}
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                  nextValue={"Next"}
                  nextAction={() => {
                    if (
                      !validateKeys(selectedMap, ["gear_type_to_be_licenced"])
                    ) {
                      alertService.warn(selectionNotFound);
                      return;
                    }

                    if (
                      selectedMap.gear_type_to_be_licenced?.indexOf("Other") >=
                      0
                    ) {
                      if (!validateKeys(selectedMap, ["other_types_of_gear"])) {
                        alertService.warn(selectionNotFound, {
                          autoClose: true,
                        });
                        return;
                      }
                    }

                    setSelectionCategory(selectionCategory + 1);
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 6 && (
            <div style={getAnimationStyle(selectionCategory === 6)}>
              <BoxChoiceContainer
                listingHeading={"Select Port or Inland"}
                listingOptions={baseLocations}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
                selectionCategory={selectionCategory}
                prop_key={"port_or_inland"}
                onClick={() => setSelectionCategory(selectionCategory + 1)}
                listingStyle="justify-content-center"
                listingDescriptions={[null, "(Rivers and Ponds)"]}
              />

              <div className="d-flex flex-column">
                <InternalNavigator
                  nextValue={"Next"}
                  nextAction={() => {
                    if (!validateKeys(selectedMap, ["port_or_inland"])) {
                      alertService.warn(selectionNotFound);
                      return;
                    }

                    setSelectionCategory(selectionCategory + 1);
                  }}
                  prevValue="Previous"
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 7 &&
            selectedMap.port_or_inland === BASE_LOCATION_INLAND && (
              <div style={getAnimationStyle(selectionCategory === 7)}>
                {/* <BoxChoiceContainer
                  listingHeading={"Select Inland"}
                  listingOptions={InlandList}
                  selectedMap={selectedMap}
                  setSelectedMap={setSelectedMap}
                  selectionCategory={selectionCategory}
                  prop_key={"inland"}
                  listingStyle="justify-content-center"
                  boxStyle="sub-cat-container"
                  textStyle="rectangle-box-small-text"
                />

                {selectedMap.inland === "Other" && (
                  <CustomInputContainer
                    heading={"Other"}
                    prop_key={"other_inland"}
                    placeholder={"Other"}
                    selectedMap={selectedMap}
                    setSelectedMap={setSelectedMap}
                  ></CustomInputContainer>
                )} */}

                <div className="d-flex-wrap flex-row justify-content-center">
                  <ReactSelect
                    options={regionListForInland}
                    label="Select Region/Parish"
                    value={selectedMap.region}
                    onChange={(e) => {
                      setSelectedMap((map) => {
                        return {
                          ...map,
                          region: e.target.value,
                          inland: "",
                        };
                      });
                    }}
                  />

                  {selectedMap.region && (
                    <ReactSelect
                      options={regionInland?.[selectedMap.region]}
                      value={selectedMap.inland}
                      label="Select Inland"
                      onChange={(e) => {
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            inland: e.target.value,
                          };
                        });
                      }}
                    />
                  )}
                </div>

                <div className="d-flex flex-column">
                  <InternalNavigator
                    nextValue={"Next"}
                    nextAction={() => {
                      if (!validateKeys(selectedMap, ["inland"])) {
                        alertService.warn(selectionNotFound, {
                          autoClose: true,
                        });
                        return;
                      }

                      if (
                        selectedMap.inland === "Other" &&
                        !validateKeys(selectedMap, ["other_inland"])
                      ) {
                        alertService.warn(inputPreviousNumber("Other Inland"), {
                          autoClose: true,
                        });
                        return;
                      }

                      props?.next();
                    }}
                    prevValue="Previous"
                    previousAction={() => {
                      setSelectionCategory(selectionCategory - 1);
                    }}
                  />
                </div>
              </div>
            )}

          {selectionCategory === 7 &&
            selectedMap.port_or_inland === BASE_LOCATION_PORT && (
              <div style={getAnimationStyle(selectionCategory === 7)}>
                <div className="d-flex-wrap flex-row justify-content-center">
                  <ReactSelect
                    options={regionParishList}
                    label="Select Region/Parish"
                    value={selectedMap.region}
                    onChange={(e) => {
                      setSelectedMap((map) => {
                        return {
                          ...map,
                          region: e.target.value,
                          port: "",
                        };
                      });
                    }}
                  />

                  {selectedMap.region && (
                    <ReactSelect
                      options={regionParishPort?.[selectedMap.region]}
                      value={selectedMap.port}
                      label="Select Port"
                      onChange={(e) => {
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            port: e.target.value,
                          };
                        });
                      }}
                    />
                  )}
                </div>

                <div className="d-flex flex-column">
                  <InternalNavigator
                    nextValue={"Next"}
                    nextAction={() => {
                      if (!validateKeys(selectedMap, ["region", "port"])) {
                        alertService.warn(selectionNotFound, {
                          autoClose: true,
                        });
                        return;
                      }
                      setSelectionCategory(selectionCategory + 1);
                      // props?.next();
                    }}
                    prevValue="Previous"
                    previousAction={() => {
                      setSelectionCategory(selectionCategory - 1);
                    }}
                  />
                </div>
              </div>
            )}

          {selectionCategory === 8 &&
            selectedMap.port_or_inland === BASE_LOCATION_PORT && (
              <div>
                <div style={getAnimationStyle(selectionCategory === 8)}>
                  <div className="d-flex flex-row justify-content-between">
                    <div>
                      {isLoaded && (
                        <div className="flex-grow-1 GoogleApp-small mt-4 ">
                          <GoogleMap
                            mapContainerClassName="map-container"
                            onLoad={onMapLoad}
                            onClick={() => setIsOpen(false)}
                          >
                            {fishingArea.map(
                              ({ location, lat, lng, id }, ind) => (
                                <Marker
                                  key={id}
                                  position={{ lat, lng }}
                                  onClick={() => {
                                    handleMarkerClick(id, lat, lng, location);
                                    onChangeMultiSelect(
                                      {
                                        target: {
                                          value: location,
                                          name: "fishing_area",
                                        },
                                      },
                                      selectedMap,
                                      setSelectedMap
                                    );
                                  }}
                                >
                                  {isOpen && infoWindowData?.id === id && (
                                    <InfoWindow
                                      onCloseClick={() => {
                                        setIsOpen(false);
                                      }}
                                    >
                                      <span>{infoWindowData.address}</span>
                                    </InfoWindow>
                                  )}
                                </Marker>
                              )
                            )}
                          </GoogleMap>
                        </div>
                      )}
                    </div>

                    <div className="ml-4 pl-2 d-flex flex-column flex-grow-1">
                      <div className="rectangle-box-small-heading">
                        Select Fishing Area(s)
                      </div>

                      <div
                        className="d-flex-wrap flex-row justify-content-start"
                        style={{ margin: "10px 30px 26px 0px" }}
                      >
                        {fishingArea?.map((fishing_area, i) => {
                          return (
                            <div
                              key={i}
                              onClick={() => {
                                onChangeMultiSelect(
                                  {
                                    target: {
                                      value: fishing_area.location,
                                      name: "fishing_area",
                                    },
                                  },
                                  selectedMap,
                                  setSelectedMap
                                );
                              }}
                              className="p-2 location-cat-container"
                              style={{
                                ...getRectBoxStylingWithMultiTypeValues(
                                  selectedMap,
                                  "fishing_area",
                                  fishing_area.location
                                ),
                                maxWidth: "130px",
                              }}
                            >
                              <div style={{ textAlign: "center" }}>
                                {getSVGImage(
                                  32,
                                  32,
                                  selectedMap["fishing_area"] ===
                                    fishing_area.location
                                    ? "black"
                                    : "#cbf1ff"
                                )}

                                <div className="rectangle-box-small-text">
                                  {fishing_area.location}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <div></div>
                    </div>
                  </div>

                  <div className="d-flex flex-column">
                    <InternalNavigator
                      nextValue={"Next"}
                      nextAction={() => {
                        if (!validateKeys(selectedMap, ["fishing_area"])) {
                          alertService.warn(selectionNotFound, {
                            autoClose: true,
                          });
                          return;
                        }

                        setSelectedMap((map) => {
                          return {
                            ...map,
                            selectionCategory,
                          };
                        });

                        // setSelectionCategory(selectionCategory + 1);
                        props?.next();
                      }}
                      prevValue="Go Back"
                      previousAction={() => {
                        setSelectionCategory(selectionCategory - 1);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
        </>
      )}

      {selectedMap.type_of_permit === TOURNAMENT_HOSTING && (
        <>
          {selectionCategory === 2 && (
            <div>
              <BoxChoiceContainer
                listingHeading={"Select Type of Transaction"}
                listingOptions={typeOfTransactions[selectedMap.type_of_permit]}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
                selectionCategory={selectionCategory}
                prop_key={"type_of_transaction"}
                listingStyle="justify-content-center"
                onClick={(value, i) => {
                  if (value !== "Renewal")
                    setSelectionCategory(selectionCategory + 1);
                }}
                listingDescriptions={["Permit + ID Card"]}
              ></BoxChoiceContainer>

              {/* {
                <div className="d-flex flex-column">
                  {["Renewal"].indexOf(selectedMap.type_of_transaction) >=
                    0 && (
                    <>
                      <div className="rectangle-box-small-heading">
                        Select Type of Renewal
                      </div>

                      <div className="d-flex flex-row justify-content-center">
                        <select
                          name="renewal_type"
                          onChange={(e) => onChangeInput(e, setSelectedMap)}
                          value={selectedMap.renewal_type}
                          className="col-sm-5 form-control form-control-sm"
                        >
                          <RenewalTypeOptions />
                        </select>
                      </div>
                    </>
                  )}
                </div>
              } */}

              {shouldAskPreviousAppNumber(selectedMap) && (
                <CustomInputContainer
                  heading={"Enter the Permit Number"}
                  prop_key={"previous_permit_number"}
                  placeholder={"Enter Permit number"}
                  selectedMap={selectedMap}
                  setSelectedMap={setSelectedMap}
                ></CustomInputContainer>
              )}

              {
                <div className="d-flex flex-column">
                  {shouldAskForNFARegNumber(selectedMap) && (
                    <>
                      <div className="rectangle-box-small-heading">
                        Enter NFA Registration Number
                      </div>

                      <div className="d-flex flex-row justify-content-center">
                        <input
                          type="text"
                          name="nfa_registration_number"
                          onChange={(e) => onChangeInput(e, setSelectedMap)}
                          value={selectedMap.nfa_registration_number}
                          className="col-sm-5 form-control form-control-sm"
                          placeholder={"Enter NFA Registration Number"}
                        />
                      </div>
                    </>
                  )}
                </div>
              }

              <div className="d-flex flex-column">
                <InternalNavigator
                  prevValue={"Previous"}
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                  nextValue={"Next"}
                  nextAction={() => {
                    if (!validateKeys(selectedMap, ["type_of_transaction"])) {
                      alertService.warn(selectionNotFound);
                      return;
                    }

                    if (
                      ["Renewal"].indexOf(selectedMap.type_of_transaction) !==
                        -1 &&
                      !validateKeys(selectedMap, ["renewal_type"])
                      // after permits renewal have no type
                      && false
                    ) {
                      alertService.warn(inputSelection("Renewal Type"));
                      return;
                    } else if (
                      shouldAskForNFARegNumber(selectedMap) &&
                      !validateKeys(selectedMap, ["nfa_registration_number"])
                    ) {
                      alertService.warn(
                        inputPreviousNumber("NFA Registration Number")
                      );
                      return;
                    } 

                    if (shouldAskPreviousAppNumber(selectedMap)) {
                      if (
                        !validateKeys(selectedMap, ["previous_permit_number"])
                      ) {
                        alertService.warn(inputPreviousNumber("Permit Number"));
                        return;
                      }
                    }

                    setSelectionCategory(selectionCategory + 1);
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 3 && (
            <div>
              <TournamentInformation
                formState={tiFormState}
                register={registerTI}
                control={tiFormControl}
                onSubmit={onSubmit}
              />

              <div className="d-flex flex-column">
                <InternalNavigator
                  nextValue={"Next"}
                  nextAction={async () => {
                    if (await validateTIForm()) {
                      setSelectionCategory(selectionCategory + 1);
                    }
                  }}
                  prevValue="Go Back"
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 4 && (
            <div>
              <TournamentDirectorInformation
                formState={tdiFormState}
                register={registerTDI}
                control={tdiFormControl}
                onSubmit={onSubmit}
              />

              <div className="d-flex flex-column">
                <InternalNavigator
                  nextValue={"Next"}
                  nextAction={async () => {
                    if (await validateTDIForm()) {
                      setSelectionCategory(selectionCategory + 1);
                    }
                  }}
                  prevValue="Go Back"
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 5 && (
            <div>
              <LocationDetails
                formState={locationFormState}
                register={registerLocation}
                control={locationFormControl}
                onSubmit={onSubmit}
                zoneBeachAreas={fishingAreaPermitLocations}
              />

              <div className="d-flex flex-column">
                <InternalNavigator
                  nextValue={"Next"}
                  nextAction={async () => {
                    if (await validateLocationForm()) {
                      setSelectedMap((map) => {
                        return {
                          ...map,
                          selectionCategory,
                        };
                      });
                      setSelectionCategory(selectionCategory + 1);
                      // props?.next();
                    }
                  }}
                  prevValue="Go Back"
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 6 && (
            <div style={getAnimationStyle(selectionCategory === 6)}>
              <BoxChoiceContainer
                listingHeading={"Select Port or Inland"}
                listingOptions={baseLocations}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
                selectionCategory={selectionCategory}
                prop_key={"port_or_inland"}
                onClick={() => setSelectionCategory(selectionCategory + 1)}
                listingStyle="justify-content-center"
                listingDescriptions={[null, "(Rivers and Ponds)"]}
              />

              <div className="d-flex flex-column">
                <InternalNavigator
                  nextValue={"Next"}
                  nextAction={() => {
                    if (!validateKeys(selectedMap, ["port_or_inland"])) {
                      alertService.warn(selectionNotFound);
                      return;
                    }

                    setSelectionCategory(selectionCategory + 1);
                  }}
                  prevValue="Previous"
                  previousAction={() => {
                    setSelectionCategory(selectionCategory - 1);
                  }}
                />
              </div>
            </div>
          )}

          {selectionCategory === 7 &&
            selectedMap.port_or_inland === BASE_LOCATION_INLAND && (
              <div style={getAnimationStyle(selectionCategory === 7)}>
                {/* <BoxChoiceContainer
                  listingHeading={"Select Inland"}
                  listingOptions={InlandList}
                  selectedMap={selectedMap}
                  setSelectedMap={setSelectedMap}
                  selectionCategory={selectionCategory}
                  prop_key={"inland"}
                  listingStyle="justify-content-center"
                  boxStyle="sub-cat-container"
                  textStyle="rectangle-box-small-text"
                />

                {selectedMap.inland === "Other" && (
                  <CustomInputContainer
                    heading={"Other"}
                    prop_key={"other_inland"}
                    placeholder={"Other"}
                    selectedMap={selectedMap}
                    setSelectedMap={setSelectedMap}
                  ></CustomInputContainer>
                )} */}

                <div className="d-flex-wrap flex-row justify-content-center">
                  <ReactSelect
                    options={regionListForInland}
                    label="Select Region/Parish"
                    value={selectedMap.region}
                    onChange={(e) => {
                      setSelectedMap((map) => {
                        return {
                          ...map,
                          region: e.target.value,
                          inland: "",
                        };
                      });
                    }}
                  />

                  {selectedMap.region && (
                    <ReactSelect
                      options={regionInland?.[selectedMap.region]}
                      value={selectedMap.inland}
                      label="Select Inland"
                      onChange={(e) => {
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            inland: e.target.value,
                          };
                        });
                      }}
                    />
                  )}
                </div>

                <div className="d-flex flex-column">
                  <InternalNavigator
                    nextValue={"Next"}
                    nextAction={() => {
                      if (!validateKeys(selectedMap, ["inland"])) {
                        alertService.warn(selectionNotFound, {
                          autoClose: true,
                        });
                        return;
                      }

                      if (
                        selectedMap.inland === "Other" &&
                        !validateKeys(selectedMap, ["other_inland"])
                      ) {
                        alertService.warn(inputPreviousNumber("Other Inland"), {
                          autoClose: true,
                        });
                        return;
                      }

                      props?.next();
                    }}
                    prevValue="Previous"
                    previousAction={() => {
                      setSelectionCategory(selectionCategory - 1);
                    }}
                  />
                </div>
              </div>
            )}

          {selectionCategory === 7 &&
            selectedMap.port_or_inland === BASE_LOCATION_PORT && (
              <div style={getAnimationStyle(selectionCategory === 7)}>
                <div className="d-flex-wrap flex-row justify-content-center">
                  <ReactSelect
                    options={regionParishList}
                    label="Select Region/Parish"
                    value={selectedMap.region}
                    onChange={(e) => {
                      setSelectedMap((map) => {
                        return {
                          ...map,
                          region: e.target.value,
                          port: "",
                        };
                      });
                    }}
                  />

                  {selectedMap.region && (
                    <ReactSelect
                      options={regionParishPort?.[selectedMap.region]}
                      value={selectedMap.port}
                      label="Select Port"
                      onChange={(e) => {
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            port: e.target.value,
                          };
                        });
                      }}
                    />
                  )}
                </div>

                <div className="d-flex flex-column">
                  <InternalNavigator
                    nextValue={"Next"}
                    nextAction={() => {
                      if (!validateKeys(selectedMap, ["region", "port"])) {
                        alertService.warn(selectionNotFound, {
                          autoClose: true,
                        });
                        return;
                      }

                      props?.next();
                    }}
                    prevValue="Previous"
                    previousAction={() => {
                      setSelectionCategory(selectionCategory - 1);
                    }}
                  />
                </div>
              </div>
            )}
        </>
      )}
    </div>
  );
}
