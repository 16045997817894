// import { default as ReactSelect } from "react-select";
// import { Option } from "./Option";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  FISHER_LICENCE,
  transactionType,
  licenceTypeMapping,
  typeOfApplicantMapping,
  typeOfTransactions,
  yesNo,
  individualYesDocList,
  individualNoDocList,
  nationality,
  fishingArea,
  typeOfSpecies,
  specialTypeOfSpecies,
  gearTypeToBeLicenced,
  applicantRoleInFisheries,
  getSVGImage,
  getRectBoxStylingWithMultiValue,
  getRectBoxStylingWithSingleValue,
  getRectBoxStylingWithMultiTypeValues,
  InternalNavigator,
  getAnimationStyle,
  onChangeMultiSelect,
  fisherCommercialLicenceCategory,
  COMMERCIAL_FISHING,
  RECREATIONAL,
  RECREATIONAL_FISHING,
  specieal_species_allow,
  NEW_LICENCE,
  RenewalTypeOptions,
  baseLocations,
  BASE_LOCATION_INLAND,
  InlandList,
  BASE_LOCATION_PORT,
  regionParishList,
  regionParishPort,
  regionListForInland,
  regionInland,
  RENEWAL,
  ID_CARD_RENEWAL,
  CONCH,
  conchTypes,
  YesNoOptions,
  YES,
} from "../../components/DataSource";
import {
  CustomInputContainer,
  onChangeInput,
} from "../../components/CustomInputContainer";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { FLISContext } from "../../context/FLISContext";

import { validateKeys, isDateInRange, validateSomeKeys, shouldAskPreviousAppNumber, shouldAskForNFARegNumber } from "../../utils/utils";
import { inputPreviousNumber, inputSelection, selectionNotFound } from "../../utils/messages";

import { alertService } from "../../_services";
import NFARegNumberSelection  from "../../components/NFARegNumberQuestion";
import BoxChoiceContainer from "../../components/BoxChoiceContainer";
import { ReactSelect } from "../../components/FormHook/ReactFormHookFields";
import { postData } from "../../services/service-call";
import { ROUTES } from "../../config";
import { icmPropMapping } from "../../components/IcmPropMappingtoUI";
import { set } from "lodash";
// import ReactSelect from "react-select";

export default function LicenseTypeSelection(props) {
  const history = useHistory();

  const { selectedMap, setSelectedMap, currentEntity } = useContext(FLISContext);

  const [selectionCategory, setSelectionCategory] = useState(selectedMap.selectionCategory ?? 2);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();

  const onMapLoad = (map) => {
    setMapRef(map);
    const bounds = new window.google.maps.LatLngBounds();
    fishingArea?.forEach(({ lat, lng }) => {
      if (lat !== 0) {
        bounds.extend({ lat, lng });
      }
    });
    map.fitBounds(bounds);
  };

  const handleMarkerClick = (id, lat, lng, address) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, address });
    setIsOpen(true);
  };


  useEffect(() => {
    if(selectedMap.licence_type === RECREATIONAL_FISHING && selectionCategory === 3){
      setSelectionCategory(selectionCategory - 1);
    }
  }, [selectedMap.licence_type, selectionCategory]);



  return (
    <div
      className="d-flex flex-column"
      style={{ width: "100%", padding: "10px 10%" }}
    >
      {/* {selectionCategory === 1 && (
        <NFARegNumberSelection
          setSelectedMap={setSelectedMap}
          selectionCategory={selectionCategory}
          setSelectionCategory={setSelectionCategory}
          history={history}
          selectedMap={selectedMap}
          allowIfNot={true}
        />
      )} */}

      {selectionCategory === 2 && (
        <div style={getAnimationStyle(selectionCategory === 2)}>
          <>
            <div className="d-flex flex-column">
              <div className="rectangle-box-small-heading">
                What Type of Licence do you want to apply for?
              </div>

              <div className="d-flex flex-wrap justify-content-center">
                {licenceTypeMapping[FISHER_LICENCE]?.map((licence_type, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            licence_type: licence_type,
                          };
                        });
                        if (licence_type === COMMERCIAL_FISHING) {
                          setSelectionCategory(selectionCategory + 1);
                        } else {
                          setSelectionCategory(selectionCategory + 2);
                        }
                      }}
                      className="p-2 license-cat-container"
                      style={getRectBoxStylingWithSingleValue(
                        selectedMap,
                        "licence_type",
                        licence_type
                      )}
                    >
                      <div style={{ textAlign: "center" }}>
                        {getSVGImage(
                          48,
                          84,
                          selectedMap["licence_type"] === licence_type
                            ? "#00b7fd"
                            : "##00b7fd"
                        )}
                        <div className="license-cat-container-text">
                          {licence_type}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="d-flex flex-column">
              <InternalNavigator
                prevValue={"Previous"}
                previousAction={() => {
                  // setSelectionCategory(selectionCategory - 1);
                  history.push("/");
                }}
                nextValue={"Next"}
                nextAction={() => {
                  if (!validateKeys(selectedMap, ["licence_type"])) {
                    alertService.warn(selectionNotFound);
                    return;
                  }

                  if (selectedMap.licence_type === COMMERCIAL_FISHING) {
                    setSelectionCategory(selectionCategory + 1);
                  } else {
                    setSelectionCategory(selectionCategory + 2);
                  }
                }}
              />
            </div>
          </>
        </div>
      )}

      {selectionCategory === 3 && (
        <div style={getAnimationStyle(selectionCategory === 3)}>
          <>
            {/* for fisher licence  */}
            <div className="d-flex flex-column">
              <div className="rectangle-box-small-heading">
                What Category of Licence do you want to apply for?
              </div>

              <div className="d-flex flex-wrap justify-content-center">
                {fisherCommercialLicenceCategory?.map((licence_type, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            commercial_category: licence_type,
                          };
                        });
                        setSelectionCategory(selectionCategory + 1);
                      }}
                      className="p-2 license-cat-container"
                      style={getRectBoxStylingWithSingleValue(
                        selectedMap,
                        "commercial_category",
                        licence_type
                      )}
                    >
                      <div style={{ textAlign: "center" }}>
                        {getSVGImage(
                          48,
                          84,
                          selectedMap["commercial_category"] === licence_type
                            ? "#00b7fd"
                            : "##00b7fd"
                        )}
                        <div className="license-cat-container-text">
                          {licence_type}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="d-flex flex-column">
              <InternalNavigator
                prevValue={"Previous"}
                previousAction={() => {
                  setSelectionCategory(selectionCategory - 1);
                }}
                nextValue={"Next"}
                nextAction={() => {
                  if (!validateKeys(selectedMap, ["commercial_category"])) {
                    alertService.warn(selectionNotFound);
                    return;
                  }

                  setSelectionCategory(selectionCategory + 1);
                }}
              />
            </div>
          </>
        </div>
      )}

      {/* {selectionCategory === 4 && (
        <div style={getAnimationStyle(selectionCategory === 4)}>
          <div className="d-flex flex-column">
            {typeOfApplicantMapping[selectedMap?.licence_type]?.length && (
              <div className="rectangle-box-small-heading">
                Select Type of Applicant
              </div>
            )}

            <div className="d-flex flex-wrap justify-content-around">
              {typeOfApplicantMapping[selectedMap?.licence_type]?.map(
                (applicant_type, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            applicant_type: applicant_type,
                          };
                        });

                        if (applicant_type === "Company") {
                          setSelectionCategory(selectionCategory + 2);
                        } else if (applicant_type === "Individual") {
                          setSelectionCategory(selectionCategory + 1);
                        } else {
                          setSelectionCategory(selectionCategory + 1);
                        }
                      }}
                      className="p-2 license-cat-container"
                      style={getRectBoxStylingWithSingleValue(
                        selectedMap,
                        "applicant_type",
                        applicant_type
                      )}
                    >
                      <div style={{ textAlign: "center" }}>
                        {getSVGImage(
                          48,
                          84,
                          selectedMap["applicant_type"] === applicant_type
                            ? "black"
                            : "#cbf1ff"
                        )}

                        <div className="license-cat-container-text">
                          {applicant_type}
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>

          <div className="d-flex flex-column">
            <InternalNavigator
              prevValue={"Previous"}
              previousAction={() => {
                if (selectedMap.licence_type === COMMERCIAL_FISHING) {
                  setSelectionCategory(selectionCategory - 1);
                } else {
                  setSelectionCategory(selectionCategory - 2);
                }
              }}
              nextValue={"Next"}
              nextAction={() => {
                if (!validateKeys(selectedMap, ["applicant_type"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }

                if (selectedMap.applicant_type === "Company") {
                  setSelectionCategory(selectionCategory + 2);
                } else if (selectedMap.applicant_type === "Individual") {
                  setSelectionCategory(selectionCategory + 1);
                } else {
                  setSelectionCategory(selectionCategory + 1);
                }
              }}
            />
          </div>
        </div>
      )} */}

      {/* {selectionCategory === 4 && (
        <div style={getAnimationStyle(selectionCategory === 4)}>
          {selectedMap?.applicant_type === "Individual" && (
            <div className="d-flex flex-column">
              <div className="rectangle-box-small-heading">
                Do you have a Picture Identification? Please select from the
                list below
              </div>

              <div className="d-flex flex-row justify-content-start">
                {yesNo?.map((trans, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            picture_identification: trans,
                          };
                        });
                      }}
                      className="p-2 sub-cat-container"
                      style={getRectBoxStylingWithSingleValue(
                        selectedMap,
                        "picture_identification",
                        trans
                      )}
                    >
                      <div style={{ textAlign: "center" }}>
                        {getSVGImage(
                          32,
                          32,
                          selectedMap["picture_identification"] === trans
                            ? "black"
                            : "#cbf1ff"
                        )}

                        <div className="rectangle-box-small">{trans}</div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="d-flex flex-column">
                {selectedMap.picture_identification === "Yes" && (
                  <div className="d-flex flex-column">
                    {individualYesDocList.map((doc, i) => {
                      return (
                        <div key={i}>
                          <div className="form-group row">
                            <label className=" col-sm-2 checkbox-container">
                              {doc}
                              <input
                                onChange={(e) =>
                                  onChangeInput(e, setSelectedMap)
                                }
                                type="checkbox"
                                value={doc}
                                checked={
                                  selectedMap?.type_of_picture_identification &&
                                  selectedMap?.type_of_picture_identification ===
                                    doc
                                }
                                name="type_of_picture_identification"
                              />
                              <span className="checkbox-checkmark"></span>
                            </label>

                            {selectedMap.type_of_picture_identification ===
                              doc && (
                              <input
                                type="text"
                                name="picture_identification_number"
                                onChange={(e) =>
                                  onChangeInput(e, setSelectedMap)
                                }
                                value={
                                  selectedMap.picture_identification_number
                                }
                                className="col-sm-5 form-control form-control-sm"
                                placeholder={doc}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {selectedMap.picture_identification === "No" && (
                  <>
                    <div className="d-flex flex-column">
                      {individualNoDocList.map((doc, i) => {
                        return (
                          <div key={i}>
                            <div className="form-group row">
                              <label className="col-sm-4 checkbox-container">
                                {doc}
                                <input
                                  onChange={(e) =>
                                    onChangeInput(e, setSelectedMap)
                                  }
                                  type="checkbox"
                                  value={doc}
                                  checked={
                                    selectedMap?.picture_identification_type_for_no &&
                                    selectedMap?.picture_identification_type_for_no ===
                                      doc
                                  }
                                  name="picture_identification_type_for_no"
                                />
                                <span className="checkbox-checkmark"></span>
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div style={{ fontSize: "18px", fontWeight: "600" }}>
                      If No - Download and fill out the attached
                      <Link
                        to="/forms/Statutory_Declaration_of_Date_of_Birth.pdf"
                        target="_blank"
                      >
                        {" "}
                        Statutory Declaration of Date of Birth{" "}
                      </Link>
                      (Upload the completed form at the “Upload Supporting
                      Documents Section)
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={() => {
                if (!validateKeys(selectedMap, ["picture_identification"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }

                if (selectedMap.picture_identification === "Yes") {
                  if (
                    !validateKeys(selectedMap, [
                      "type_of_picture_identification",
                      "picture_identification_number",
                    ])
                  ) {
                    alertService.warn(selectionNotFound);
                    return;
                  }
                } else if (selectedMap.picture_identification === "No") {
                  if (
                    !validateKeys(selectedMap, [
                      "picture_identification_type_for_no",
                    ])
                  ) {
                    alertService.warn(selectionNotFound);
                    return;
                  }
                }
                setSelectionCategory(selectionCategory + 1);
              }}
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
              prevValue="Previous"
            />
          </div>
        </div>
      )} */}

      {selectionCategory === 4 && (
        <div style={getAnimationStyle(selectionCategory === 4)}>
          {
            <div className="d-flex flex-column">
              <div className="rectangle-box-small-heading">
                Select type of transaction
              </div>

              <div className="d-flex-wrap flex-row justify-content-center">
                {typeOfTransactions[FISHER_LICENCE]?.map((trans) => {
                  return (
                    <div
                      onClick={() => {
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            type_of_transaction: trans,
                          };
                        });

                        if (trans === NEW_LICENCE) {
                          setSelectionCategory(selectionCategory + 1);
                        }
                      }}
                      className={`p-2 sub-cat-container ${
                        trans === NEW_LICENCE &&
                        selectedMap.nfa_registration_number
                          ? "non-functional-element"
                          : ""
                      }`}
                      style={getRectBoxStylingWithSingleValue(
                        selectedMap,
                        "type_of_transaction",
                        trans
                      )}
                    >
                      <div style={{ textAlign: "center" }}>
                        {getSVGImage(
                          32,
                          32,
                          selectedMap["type_of_transaction"] === trans
                            ? "black"
                            : "#cbf1ff"
                        )}

                        <div className="rectangle-box-small">{trans}</div>
                        {trans === NEW_LICENCE && (
                          <div>(Licence + ID Card)</div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          }

          {
            <div className="d-flex flex-column">
              {["Renewal"].indexOf(selectedMap.type_of_transaction) >= 0 && (
                <>
                  <div className="rectangle-box-small-heading">
                    Select Type of Renewal
                  </div>

                  <div className="d-flex flex-row justify-content-center">
                    <select
                      name="renewal_type"
                      onChange={(e) => onChangeInput(e, setSelectedMap)}
                      value={selectedMap.renewal_type}
                      className="col-sm-5 form-control form-control-sm"
                    >
                      <RenewalTypeOptions />
                    </select>
                  </div>
                </>
              )}
            </div>
          }

          {
            <div className="d-flex flex-column">
              {shouldAskPreviousAppNumber(selectedMap) && (
                <>
                  <div className="rectangle-box-small-heading">
                    Do you have your Fisher Licence #
                  </div>

                  <div className="d-flex flex-row justify-content-center">
                    <select
                      name="have_licence_number"
                      onChange={(e) => onChangeInput(e, setSelectedMap)}
                      value={selectedMap.have_licence_number}
                      className="col-sm-5 form-control form-control-sm"
                    >
                      <YesNoOptions />
                    </select>
                  </div>

                  {selectedMap.have_licence_number === YES && (
                    <>
                      <div className="rectangle-box-small-heading">
                        Enter your Fisher Licence #
                      </div>

                      <div className="d-flex flex-row justify-content-center">
                        <input
                          type="text"
                          name="previous_licence_number"
                          onChange={(e) => onChangeInput(e, setSelectedMap)}
                          value={selectedMap.previous_licence_number}
                          className="col-sm-5 form-control form-control-sm"
                          placeholder={"Enter Licence Number"}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          }

          {
            <div className="d-flex flex-column">
              {shouldAskForNFARegNumber(selectedMap) && (
                <>
                  <div className="rectangle-box-small-heading">
                    Enter NFA Registration Number
                  </div>

                  <div className="d-flex flex-row justify-content-center">
                    <input
                      type="text"
                      name="nfa_registration_number"
                      onChange={(e) => onChangeInput(e, setSelectedMap)}
                      value={selectedMap.nfa_registration_number}
                      className="col-sm-5 form-control form-control-sm"
                      placeholder={"Enter NFA Registration Number"}
                    />
                  </div>
                </>
              )}
            </div>
          }

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={async () => {
                if (!validateKeys(selectedMap, ["type_of_transaction"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }

                if (
                  ["Renewal"].indexOf(selectedMap.type_of_transaction) !== -1 &&
                  !validateKeys(selectedMap, ["renewal_type"])
                ) {
                  alertService.warn(inputSelection("Renewal Type"));
                  return;
                } else if (
                  shouldAskPreviousAppNumber(selectedMap) &&
                  shouldAskForNFARegNumber(selectedMap) &&
                  !validateKeys(selectedMap, ["nfa_registration_number"])
                ) {
                  alertService.warn(
                    inputPreviousNumber("NFA Registration Number")
                  );
                  return;
                }

                if(shouldAskPreviousAppNumber(selectedMap) && !validateKeys(selectedMap, ["have_licence_number"])) {
                  alertService.warn(
                    inputPreviousNumber("Have Fisher Licence #")
                  );
                  return;
                }

                if (
                  shouldAskPreviousAppNumber(selectedMap) && selectedMap.have_licence_number === YES &&
                  !validateKeys(selectedMap, ["previous_licence_number"])
                ) {
                  alertService.warn(inputPreviousNumber("Licence Number"));
                  return;
                } else if (
                  shouldAskPreviousAppNumber(selectedMap) &&
                  validateKeys(selectedMap, ["previous_licence_number"]) &&
                  process.env.REACT_APP_DO_RENEWAL_API == 1
                ) {
                  // do api call for getting the renewal data here
                  const result = await postData({
                    url: `${ROUTES.getFisherRenewalData}`,
                    body: {
                      licenceNumber: selectedMap.previous_licence_number,
                      nfaRegistrationNumber:
                        selectedMap.nfa_registration_number,
                      entityNumber: currentEntity.entity_number,
                      // licenceNumber: "DS-00000-24",
                      // nfaRegistrationNumber: "nfa_reg_number_58",
                      // entityNumber: "IN-0677734",
                    },
                  });

                  let map = {};
                  Object.keys(result?.data?.result ?? []).forEach((key) => {
                    // map[icmPropMapping['APPLICATION_FOR_LICENCE'][key] || ''] = result?.data?.result[key];
                    if (icmPropMapping["APPLICATION_FOR_LICENCE"][key])
                      set(
                        map,
                        icmPropMapping["APPLICATION_FOR_LICENCE"][key],
                        result?.data?.result[key]
                      );
                  });
                  console.log(map);
                  setSelectedMap((data) => {
                    return {
                      ...data,
                      ...map,
                    };
                  });
                }

                setSelectionCategory(selectionCategory + 1);
              }}
              prevValue="Previous"
              previousAction={() => {
                if (selectedMap.applicant_type === "Company") {
                  setSelectionCategory(selectionCategory - 2);
                } else if (selectedMap.applicant_type === "Individual") {
                  setSelectionCategory(selectionCategory - 1);
                } else {
                  setSelectionCategory(selectionCategory - 1);
                }
              }}
            />
          </div>
        </div>
      )}

      {selectionCategory === 5 && (
        <div style={getAnimationStyle(selectionCategory === 5)}>
          <BoxChoiceContainer
            listingHeading={"Select Port or Inland"}
            listingOptions={baseLocations}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"port_or_inland"}
            onClick={() => setSelectionCategory(selectionCategory + 1)}
            listingStyle="justify-content-center"
            listingDescriptions={[null, "(Rivers and Ponds)"]}
          />

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={() => {
                if (!validateKeys(selectedMap, ["port_or_inland"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }

                setSelectionCategory(selectionCategory + 1);
              }}
              prevValue="Previous"
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
            />
          </div>
        </div>
      )}

      {selectionCategory === 6 &&
        selectedMap.port_or_inland === BASE_LOCATION_INLAND && (
          <div style={getAnimationStyle(selectionCategory === 6)}>
            {/* <BoxChoiceContainer
              listingHeading={"Select Inland"}
              listingOptions={InlandList}
              selectedMap={selectedMap}
              setSelectedMap={setSelectedMap}
              selectionCategory={selectionCategory}
              prop_key={"inland"}
              listingStyle="justify-content-center"
              boxStyle="sub-cat-container"
              textStyle="rectangle-box-small-text"
            />

            {selectedMap.inland === "Other" && (
              <CustomInputContainer
                heading={"Other"}
                prop_key={"other_inland"}
                placeholder={"Other"}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
              ></CustomInputContainer>
            )} */}

            <div className="d-flex-wrap flex-row justify-content-center">
              <ReactSelect
                options={regionListForInland}
                label="Select Region/Parish"
                value={selectedMap.region}
                onChange={(e) => {
                  setSelectedMap((map) => {
                    return {
                      ...map,
                      region: e.target.value,
                      inland: "",
                    };
                  });
                }}
              />

              {selectedMap.region && (
                <ReactSelect
                  options={regionInland?.[selectedMap.region]}
                  value={selectedMap.inland}
                  label="Select Inland"
                  onChange={(e) => {
                    setSelectedMap((map) => {
                      return {
                        ...map,
                        inland: e.target.value,
                      };
                    });
                  }}
                />
              )}
            </div>

            <div className="d-flex flex-column">
              <InternalNavigator
                nextValue={"Next"}
                nextAction={() => {
                  if (!validateKeys(selectedMap, ["inland"])) {
                    alertService.warn(selectionNotFound);
                    return;
                  }

                  if (
                    selectedMap.inland === "Other" &&
                    !validateKeys(selectedMap, ["other_inland"])
                  ) {
                    alertService.warn(inputPreviousNumber("Other Inland"), {
                      autoClose: true,
                    });
                    return;
                  }

                  setSelectionCategory(selectionCategory + 2);
                }}
                prevValue="Previous"
                previousAction={() => {
                  setSelectionCategory(selectionCategory - 1);
                }}
              />
            </div>
          </div>
        )}

      {selectionCategory === 6 &&
        selectedMap.port_or_inland === BASE_LOCATION_PORT && (
          <div style={getAnimationStyle(selectionCategory === 6)}>
            <div className="d-flex-wrap flex-row justify-content-center">
              <ReactSelect
                options={regionParishList}
                label="Select Region/Parish"
                value={selectedMap.region}
                onChange={(e) => {
                  setSelectedMap((map) => {
                    return {
                      ...map,
                      region: e.target.value,
                      port: "",
                    };
                  });
                }}
              />

              {selectedMap.region && (
                <ReactSelect
                  options={regionParishPort?.[selectedMap.region]}
                  value={selectedMap.port}
                  label="Select Port"
                  onChange={(e) => {
                    setSelectedMap((map) => {
                      return {
                        ...map,
                        port: e.target.value,
                      };
                    });
                  }}
                />
              )}
            </div>

            <div className="d-flex flex-column">
              <InternalNavigator
                nextValue={"Next"}
                nextAction={() => {
                  if (!validateKeys(selectedMap, ["region", "port"])) {
                    alertService.warn(selectionNotFound);
                    return;
                  }

                  setSelectionCategory(selectionCategory + 1);
                }}
                prevValue="Previous"
                previousAction={() => {
                  setSelectionCategory(selectionCategory - 1);
                }}
              />
            </div>
          </div>
        )}

      {selectionCategory === 7 &&
        selectedMap.port_or_inland === BASE_LOCATION_PORT && (
          <div style={getAnimationStyle(selectionCategory === 7)}>
            <div className="d-flex flex-row justify-content-between">
              <div>
                {isLoaded && (
                  <div className="flex-grow-1 GoogleApp-small mt-4 ">
                    <GoogleMap
                      mapContainerClassName="map-container"
                      onLoad={onMapLoad}
                      onClick={() => setIsOpen(false)}
                    >
                      {fishingArea.map(({ location, lat, lng, id }, ind) => (
                        <Marker
                          key={id}
                          position={{ lat, lng }}
                          onClick={() => {
                            handleMarkerClick(id, lat, lng, location);
                            onChangeMultiSelect(
                              {
                                target: {
                                  value: location,
                                  name: "fishing_area",
                                },
                              },
                              selectedMap,
                              setSelectedMap
                            );
                          }}
                        >
                          {isOpen && infoWindowData?.id === id && (
                            <InfoWindow
                              onCloseClick={() => {
                                setIsOpen(false);
                              }}
                            >
                              <span>{infoWindowData.address}</span>
                            </InfoWindow>
                          )}
                        </Marker>
                      ))}
                    </GoogleMap>
                  </div>
                )}
              </div>

              <div className="ml-4 pl-2 d-flex flex-column flex-grow-1">
                <div className="rectangle-box-small-heading">
                  Select Fishing Area(s)
                </div>

                <div
                  className="d-flex-wrap flex-row justify-content-start"
                  style={{ margin: "10px 30px 26px 0px" }}
                >
                  {fishingArea?.map((fishing_area, i) => {
                    return (
                      <div
                        key={i}
                        onClick={() => {
                          onChangeMultiSelect(
                            {
                              target: {
                                value: fishing_area.location,
                                name: "fishing_area",
                              },
                            },
                            selectedMap,
                            setSelectedMap
                          );
                        }}
                        className="p-2 location-cat-container"
                        style={{
                          ...getRectBoxStylingWithMultiTypeValues(
                            selectedMap,
                            "fishing_area",
                            fishing_area.location
                          ),
                          maxWidth: "130px",
                        }}
                      >
                        <div style={{ textAlign: "center" }}>
                          {getSVGImage(
                            32,
                            32,
                            selectedMap["fishing_area"] ===
                              fishing_area.location
                              ? "black"
                              : "#cbf1ff"
                          )}

                          <div className="rectangle-box-small-text">
                            {fishing_area.location}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div></div>
              </div>
            </div>

            <div className="d-flex flex-column">
              <InternalNavigator
                nextValue={"Next"}
                nextAction={() => {
                  if (!validateKeys(selectedMap, ["fishing_area"])) {
                    alertService.warn(selectionNotFound);
                    return;
                  }

                  setSelectionCategory(selectionCategory + 1);
                }}
                prevValue="Previous"
                previousAction={() => {
                  setSelectionCategory(selectionCategory - 1);
                }}
              />
            </div>
          </div>
        )}

      {selectionCategory === 8 && (
        <div style={getAnimationStyle(selectionCategory === 8)}>
          {
            <div className="d-flex flex-column">
              <div className="rectangle-box-small-heading">
                Select Type of Species
              </div>

              <div className="d-flex flex-row flex-wrap justify-content-center">
                {typeOfSpecies?.map((specie) => {
                  return (
                    <div
                      onClick={() =>
                        onChangeMultiSelect(
                          {
                            target: {
                              name: "type_of_species",
                              value: specie,
                            },
                          },
                          selectedMap,
                          setSelectedMap
                        )
                      }
                      className="p-2 sub-cat-container"
                      style={getRectBoxStylingWithMultiValue(
                        selectedMap,
                        "type_of_species",
                        specie
                      )}
                    >
                      <div style={{ textAlign: "center" }}>
                        {getSVGImage(
                          24,
                          24,
                          selectedMap["type_of_species"]?.indexOf?.(specie) >= 0
                            ? "black"
                            : "#cbf1ff"
                        )}

                        <div className="rectangle-box-small">{specie}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          }

          {selectedMap.licence_type !== RECREATIONAL_FISHING && (
            <>
              <div className="d-flex flex-column">
                <div className="rectangle-box-small-heading">
                  Select Type of Species (Special)
                </div>

                <div className="d-flex flex-row flex-wrap justify-content-center">
                  {specialTypeOfSpecies?.map((specie, i) => {
                    const isEnabled = isDateInRange(
                      specieal_species_allow[specie].start_date,
                      specieal_species_allow[specie].end_date
                    );
                    return (
                      <div
                        key={i}
                        onClick={() => {
                          onChangeInput(
                            {
                              target: {
                                name: "special_type_of_species",
                                value:
                                  selectedMap.special_type_of_species === specie
                                    ? null
                                    : specie,
                              },
                            },
                            setSelectedMap
                          );
                        }}
                        className={`p-2 license-cat-container ${
                          isEnabled ? "" : "non-functional-element"
                        }`}
                        style={getRectBoxStylingWithMultiValue(
                          selectedMap,
                          "special_type_of_species",
                          specie
                        )}
                      >
                        <div style={{ textAlign: "center" }}>
                          {getSVGImage(
                            24,
                            24,
                            selectedMap["special_type_of_species"]?.indexOf?.(
                              specie
                            ) >= 0
                              ? "black"
                              : "#cbf1ff"
                          )}

                          <div className="rectangle-box-small">{specie}</div>
                          <span>
                            {specieal_species_allow[specie].description}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {selectedMap.special_type_of_species === CONCH && (
                <div className="d-flex flex-column">
                  <div className="rectangle-box-small-heading">
                    Select Type of Conch)
                  </div>

                  <div className="d-flex flex-row flex-wrap justify-content-center">
                    {conchTypes?.map((specie, i) => {
                      return (
                        <div
                          key={i}
                          onClick={() => {
                            onChangeInput(
                              {
                                target: {
                                  name: "type_of_conch",
                                  value:
                                    selectedMap.type_of_conch === specie
                                      ? null
                                      : specie,
                                },
                              },
                              setSelectedMap
                            );
                          }}
                          className={`p-2 license-cat-container`}
                          style={getRectBoxStylingWithMultiValue(
                            selectedMap,
                            "type_of_conch",
                            specie
                          )}
                        >
                          <div style={{ textAlign: "center" }}>
                            {getSVGImage(
                              24,
                              24,
                              selectedMap["type_of_conch"]?.indexOf(specie) >= 0
                                ? "black"
                                : "#cbf1ff"
                            )}

                            <div className="rectangle-box-small">{specie}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          )}

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={() => {
                if (
                  !validateSomeKeys(selectedMap, [
                    "type_of_species",
                    "special_type_of_species",
                  ])
                ) {
                  alertService.warn(
                    "You must select either Species or Special Species",
                    { autoClose: true }
                  );
                  return;
                }

                if (
                  selectedMap.special_type_of_species === CONCH &&
                  !validateKeys(selectedMap, ["type_of_conch"])
                ) {
                  alertService.warn(
                    "You must select Type of Conch before proceeding",
                    { autoClose: true }
                  );
                  return;
                }

                setSelectionCategory(selectionCategory + 1);
              }}
              prevValue="Previous"
              previousAction={() => {
                if (selectedMap.port_or_inland === BASE_LOCATION_PORT) {
                  setSelectionCategory(selectionCategory - 1);
                } else {
                  setSelectionCategory(selectionCategory - 2);
                }
              }}
            />
          </div>
        </div>
      )}

      {selectionCategory === 9 && (
        <div style={getAnimationStyle(selectionCategory === 9)}>
          {
            <div className="d-flex flex-column">
              <div className="rectangle-box-small-heading">
                Select Gear type to be licenced
              </div>

              <div className="d-flex flex-row flex-wrap justify-content-center">
                {gearTypeToBeLicenced?.map((trans) => {
                  return (
                    <div
                      onClick={() =>
                        onChangeMultiSelect(
                          {
                            target: {
                              name: "gear_type_to_be_licenced",
                              value: trans,
                            },
                          },
                          selectedMap,
                          setSelectedMap
                        )
                      }
                      className="p-2 sub-cat-container"
                      style={getRectBoxStylingWithMultiValue(
                        selectedMap,
                        "gear_type_to_be_licenced",
                        trans
                      )}
                    >
                      <div style={{ textAlign: "center" }}>
                        {getSVGImage(
                          24,
                          24,
                          selectedMap["gear_type_to_be_licenced"]?.indexOf(
                            trans
                          ) >= 0
                            ? "black"
                            : "#cbf1ff"
                        )}

                        <div className="rectangle-box-small">{trans}</div>
                      </div>
                    </div>
                  );
                })}

                {selectedMap?.gear_type_to_be_licenced?.indexOf("Other") >=
                  0 && (
                  //   <CustomInputContainer
                  //   heading={"Other"}
                  //   prop_key={"other_types_of_gears"}
                  //   placeholder={"Other"}
                  //   selectedMap={selectedMap}
                  //   setSelectedMap={setSelectedMap}
                  // ></CustomInputContainer>

                  <div>
                    <label>Other Type of Gear</label>
                    <br></br>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Other"
                      value={selectedMap.other_types_of_gears}
                      name="other_types_of_gears"
                      onChange={(e) => onChangeInput(e, setSelectedMap)}
                    />
                  </div>
                )}
              </div>
            </div>
          }

          {
            <div className="d-flex flex-column">
              <div className="rectangle-box-small-heading">
                Select Applicant's Role in Fisheries
              </div>

              <div className="d-flex flex-row flex-wrap justify-content-center">
                {applicantRoleInFisheries?.map((trans) => {
                  return (
                    <div
                      onClick={() =>
                        onChangeMultiSelect(
                          {
                            target: {
                              name: "applicant_role_in_fisheries",
                              value: trans,
                            },
                          },
                          selectedMap,
                          setSelectedMap
                        )
                      }
                      className="p-2 sub-cat-container"
                      style={getRectBoxStylingWithMultiValue(
                        selectedMap,
                        "applicant_role_in_fisheries",
                        trans
                      )}
                    >
                      <div style={{ textAlign: "center" }}>
                        {getSVGImage(
                          24,
                          24,
                          selectedMap["applicant_role_in_fisheries"]?.indexOf(
                            trans
                          ) >= 0
                            ? "black"
                            : "#cbf1ff"
                        )}

                        <div className="rectangle-box-small">{trans}</div>
                      </div>
                    </div>
                  );
                })}

                {selectedMap?.applicant_role_in_fisheries?.indexOf("Other") >=
                  0 && (
                  <div>
                    <label>Other Applicant's Role</label>
                    <br></br>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Other"
                      value={selectedMap.other_applicant_role}
                      name="other_applicant_role"
                      onChange={(e) => onChangeInput(e, setSelectedMap)}
                    />
                  </div>
                )}
              </div>
            </div>
          }

          <div className="d-flex flex-column">
            <InternalNavigator
              prevValue="Previous"
              previousAction={() => setSelectionCategory(selectionCategory - 1)}
              nextValue={"Next"}
              nextAction={() => {
                if (
                  !validateKeys(selectedMap, [
                    "gear_type_to_be_licenced",
                    "applicant_role_in_fisheries",
                  ])
                ) {
                  alertService.warn(selectionNotFound);
                  return;
                }

                if (
                  selectedMap.gear_type_to_be_licenced?.indexOf("Other") >= 0 &&
                  !validateKeys(selectedMap, ["other_types_of_gears"])
                ) {
                  alertService.warn(selectionNotFound);
                  return;
                }
                if (
                  selectedMap?.applicant_role_in_fisheries?.indexOf("Other") >=
                    0 &&
                  !validateKeys(selectedMap, ["other_applicant_role"])
                ) {
                  alertService.warn(selectionNotFound);
                  return;
                }

                setSelectedMap((map) => {
                  return {
                    ...map,
                    selectionCategory,
                  };
                });

                props?.next();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}